import { useEffect, useRef } from "react"

export const useChatAutoScroll = (messages, isStreaming) => {
  const chatMessagesContainerRef = useRef(null)

  useEffect(() => {
    if (!messages.length) return

    setTimeout(() => {
      chatMessagesContainerRef.current.scrollTo({
        top: chatMessagesContainerRef.current.scrollHeight,
        behavior: "smooth"
      })
    }, 100)
  }, [messages.length])

  return {
    chatMessagesContainerRef
  }
}
