import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardHeader, CardText, CardTitle, Col, Row } from "reactstrap"
import Images from "utils/Images"
import FilterComponent from "WebVisual/Components/FilterComponent"
import moment from "moment"
import Pagination from "components/Pagination/Pagination"
import ClientCallsDetail from "../ClientCallsDetail"

import { constants } from "./redux"
import "react-datepicker/dist/react-datepicker.css"
import "./style.scss"
import ActivityIndicator from "components/ActivityIndicator"
import { updateAnalyticsAction } from "../Settings/redux"

const ClientCalls = ({ search }) => {
  const dispatch = useDispatch()
  const state = useSelector(state => state.VisualClientCallsReducer)
  const [offset, setOffset] = useState(0)
  const [selectPageValue, setSelectPageValue] = useState(1)

  const getClientCalls = () => {
    dispatch({ type: constants.GET_CLIENTCALLS, offset: offset })
  }

  useEffect(() => {
    getClientCalls()
  }, [offset])

  useEffect(() => {
    if (search) {
      dispatch({
        type: constants.SEARCH_CLIENTCALLS,
        search
      })
    } else {
      getClientCalls()
    }
  }, [search])

  const [isToggle, setIsToggle] = useState(false)
  const [isDateToggle, setIsDateToggle] = useState(false)
  const [date, setDate] = useState("")
  const [category, seCategory] = useState("")
  const [selectedClientCall, setselectedClientCall] = useState(false)

  const onApplyFilter = (type, params) => {
    let dateQuery = date
    let categoryQuery = category
    if (type === "date") {
      setDate(params)
      dateQuery = params
    } else {
      seCategory(params)
      categoryQuery = params
    }
    dispatch({
      type: constants.DATE_FILETER,
      params: dateQuery + categoryQuery
    })
    setIsToggle(false)
    setIsDateToggle(false)
  }

  const onClickClientCalls = (type, item, category) => {
    dispatch(updateAnalyticsAction(type, item, category))
  }

  return (
    <>
      {selectedClientCall ? (
        <ClientCallsDetail
          type="clientCalls"
          item={selectedClientCall}
          activeCallback={setselectedClientCall}
        />
      ) : (
        <div className="clientCallsWrapper">
          <FilterComponent
            onApplyFilter={onApplyFilter}
            isToggle={isToggle}
            setIsToggle={setIsToggle}
            isDateToggle={isDateToggle}
            setIsDateToggle={setIsDateToggle}
            listFound={`${
              state.count || state.clientcalls.length || 0
            } Client calls found`}
          />
          <Row className="article-wrapper">
            {state.requesting ? (
              <ActivityIndicator requesting />
            ) : !state.requesting && state.clientcalls.length < 1 ? (
              <Row className="d-flex justify-content-center align-items-center m-auto">
                <p className="">No content found.</p>
              </Row>
            ) : state.requesting ? (
              <ActivityIndicator requesting />
            ) : (
              state.clientcalls.map(item => (
                <Col
                  md="6"
                  xl="4"
                  className="mb-5"
                  style={{ flex: "auto", display: "flex" }}
                >
                  <Card
                    style={{
                      flexDirection: "inherit",
                      flex: 1,
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Row className="w-100 justify-content-start">
                      <Col md="2" className="cardHeadWrapper p-0">
                        <div className="cardBox">
                          <CardHeader className="cardHeader">
                            <img src={Images.cardLogo} />
                          </CardHeader>
                        </div>
                      </Col>
                      <Col md="10" className="cardDetailWrapper px-3 px-md-0">
                        <CardTitle className="cardTitle">
                          <p
                            className="podcast-card-title"
                            onClick={() => {
                              setselectedClientCall(item)
                              onClickClientCalls(
                                "client_calls",
                                item.id,
                                "view"
                              )
                            }}
                          >
                            {item.title}
                          </p>
                        </CardTitle>
                        <CardText>
                          <Row className="cardData">
                            <p className="categoryText">Category:</p>
                            <p className="categoryType">{item.category}</p>
                          </Row>
                          <p className="categoryDetail">{item.short_summary}</p>
                          <div className="d-flex justify-content-between dateWrraper">
                            <p>{moment(item.date).format("MMMM, DD, YYYY")}</p>
                            <img
                              className="mediaIcons"
                              src={
                                item.media.includes("mp4")
                                  ? Images.videoIcon
                                  : Images.audioIcon
                              }
                              alt=""
                            />
                          </div>
                        </CardText>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))
            )}
          </Row>
          {state.count > 10 && (
            <Row className="align-items-center justify-content-center">
              {state.requesting ? (
                ""
              ) : (
                <Pagination
                  totalCount={state && state.count}
                  offset={offset}
                  setOffset={setOffset}
                  selectPageValue={selectPageValue}
                  setSelectPageValue={setSelectPageValue}
                />
              )}
            </Row>
          )}
        </div>
      )}
    </>
  )
}

export default ClientCalls
