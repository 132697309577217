import { all, call, put, takeLatest } from "redux-saga/effects"
import { toast } from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  SEARCH_USER,
  FILTER_USER,
  DELETE_USER,
  GET_ALL_USERS_REQUEST,
  CREATE_USERS_REQUEST,
  UPDATE_USERS_REQUEST,
  SUSPEND_USER,
  GET_PREFERRED_INTEREST
} from "./types"

// actions
import {
  usersListRequest,
  usersListSuccess,
  usersListFaluire,
  createUserSuccess,
  createUserFaluire,
  updateUserFaluire,
  searchUsersFailure,
  searchUsersSuccess,
  filterUsersFailure,
  filterUsersSuccess,
  deleteUserFailure,
  deleteUserSuccess,
  getPreferredInterestSuccess,
  getPreferredInterestFaluire
} from "./actions"
import { nonAdminErrorMessage } from "../../../utils/functions"

async function getUsersAPI(data) {
  const accessToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/admin/users/?limit=10&offset=${data.offset}`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getUsers({ data }) {
  try {
    const response = yield call(getUsersAPI, data)
    yield put(usersListSuccess(response?.data))
  } catch (e) {
    let err = e?.response?.data?.non_field_errors
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(usersListFaluire("Connection Error"))
    } else if (e?.response?.data?.detail) {
      nonAdminErrorMessage(e.response.data.detail)
      yield put(usersListFaluire({}))
    } else {
      toast.error(err.toString())
      yield put(usersListFaluire(err))
    }
  }
}

async function createUsersAPI(data) {
  const accessToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/create_user/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }

  return XHR(URL, options)
}

function* createUsers({ data, toggle }) {
  try {
    const response = yield call(createUsersAPI, data)
    yield put(createUserSuccess(response))
    toast.success("User Created !")
    yield put(usersListRequest({ offset: 0 }))
    toggle()
  } catch (e) {
    const error = e?.response?.data
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(createUserFaluire("Connection Error"))
    } else {
      yield put(createUserFaluire(error))
    }
  }
}

async function updateUsersAPI(data) {
  const accessToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/create_user/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json"
    },
    method: "PATCH",
    data
  }

  return XHR(URL, options)
}

function* updateUsers({ data, modalVisible, offset = 0 }) {
  try {
    yield call(updateUsersAPI, data)
    modalVisible(false)
    toast.success("User updated successfully!")
    yield put(usersListRequest({ offset }))
  } catch (e) {
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(updateUserFaluire("Connection Error"))
    }
  }
}

async function filterUserAPI(data, offset) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/admin/users/?type=filter&limit=10&offset=${offset}&is_trial_period=${data.isTrial}&is_subscribed=${data.isSubscribed}`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* filterUserData({ data, offset }) {
  try {
    const response = yield call(filterUserAPI, data, offset)
    yield put(filterUsersSuccess(response?.data))
  } catch (e) {
    let err = e?.response?.data
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(filterUsersFailure("Connection Error"))
    } else {
      toast.error(err)
      yield put(filterUsersFailure(err))
    }
  }
}

async function searchUserAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/admin/users/?type=filter&email=${data.email}`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* searchUserData({ data, setIsToggle }) {
  try {
    const response = yield call(searchUserAPI, data)
    yield put(searchUsersSuccess(response?.data))
  } catch (e) {
    let err = e?.response?.data //?.non_field_errors
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(searchUsersFailure("Connection Error"))
    } else {
      toast.error(err)
      yield put(searchUsersFailure(err))
    }
  }
}

async function deleteUserAPI(data) {
  const URL = `${BASE_URL}/api/v1/admin/users/${data.id}/`
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "DELETE",
    data: data.del
  }
  return XHR(URL, options)
}

function* deleteUserFunction({ data, toggle }) {
  try {
    const response = yield call(deleteUserAPI, data)
    yield put(deleteUserSuccess(response))
    yield put(usersListRequest({ offset: 0 }))
    toggle()
    toast.success("Delete user successfully")
  } catch (e) {
    yield put(deleteUserFailure(e.response.data))
  }
}

async function suspendUserAPI(data) {
  const accessToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/admin/users/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json"
    },
    method: "PATCH",
    data
  }

  return XHR(URL, options)
}

function* suspendUser({ data, setModal1, offset = 0 }) {
  try {
    const response = yield call(suspendUserAPI, data)
    response && setModal1(false)
    yield put(usersListRequest({ offset }))
    toast.success(
      response?.data?.is_suspended
        ? "Suspend User Successfully"
        : "Un-Suspend User Successfully"
    )
  } catch (e) {
    const { err } = e
    if (e.message === "Network Error") {
      toast.error("Connection Error")
    } else {
      toast.error(err)
    }
  }
}

async function preferredDataAPI(data) {
  const accessToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/admin/users/${data}/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* preferredData({ data }) {
  try {
    const response = yield call(preferredDataAPI, data)
    yield put(getPreferredInterestSuccess(response.data.interest))
  } catch (e) {
    toast.error("Failed to get preferred interests.")
    yield put(getPreferredInterestFaluire())
  }
}

export default all([
  takeLatest(GET_ALL_USERS_REQUEST, getUsers),
  takeLatest(CREATE_USERS_REQUEST, createUsers),
  takeLatest(UPDATE_USERS_REQUEST, updateUsers),
  takeLatest(SEARCH_USER, searchUserData),
  takeLatest(FILTER_USER, filterUserData),
  takeLatest(DELETE_USER, deleteUserFunction),
  takeLatest(SUSPEND_USER, suspendUser),
  takeLatest(GET_PREFERRED_INTEREST, preferredData)
])
