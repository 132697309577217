import { all, call, put, takeLatest } from "redux-saga/effects"

import XHR from "../../../utils/XHR"

import { BASE_URL } from "config/app"
import { toast } from "react-hot-toast"
import { updateAnalyticsAction } from "../Settings/redux"

const TYPE = "WEBVISUAL_CLIENTCALLS_"
export const constants = {
  GET_CLIENTCALLS: `${TYPE}GET_CLIENTCALLS`,
  GET_CLIENTCALLS_SUCCESS: `${TYPE}GET_CLIENTCALLS_SUCCESS`,
  RESET_REQUEST: `${TYPE}RESET_REQUEST`,
  SEARCH_CLIENTCALLS: `${TYPE}SEARCH_CLIENTCALLS`,
  DATE_FILETER: `${TYPE}DATE_FILETER`,
  DOWNLOAD_CLIENT_CALLS: `${TYPE}DOWNLOAD_CLIENT_CALLS`,
  DOWNLOAD_CLIENT_CALLS_SUCCESS: `${TYPE}DOWNLOAD_CLIENT_CALLS_SUCCESS`
}

const initialState = {
  requesting: false,
  clientcalls: [],
  count: 0,
  downloadRequesting: false
}

export const VisualClientCallsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.DATE_FILETER:
    case constants.GET_CLIENTCALLS:
    case constants.SEARCH_CLIENTCALLS:
      return {
        ...state,
        requesting: true
      }
    case constants.GET_CLIENTCALLS_SUCCESS:
      return {
        ...state,
        clientcalls: action.response,
        count: action.count,
        requesting: false
      }

    case constants.RESET_REQUEST:
      return {
        ...state,
        requesting: false
      }
    case constants.DOWNLOAD_CLIENT_CALLS:
      return {
        ...state,
        downloadRequesting: true
      }

    case constants.DOWNLOAD_CLIENT_CALLS_SUCCESS:
      return {
        ...state,
        downloadRequesting: false
      }

    default:
      return state
  }
}

async function getClientCallsAPI(offset) {
  const URL = `${BASE_URL}/api/v1/client_call/?limit=10&offset=${offset}`
  const prc_authToken = localStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getClientCalls({ offset }) {
  try {
    const response = yield call(getClientCallsAPI, offset)
    yield put({
      type: constants.GET_CLIENTCALLS_SUCCESS,
      response: response.data.results,
      count: response.data.count
    })
  } catch (e) {
    yield put({ type: constants.RESET_REQUEST })
    toast.error("Failed to get client calls")
  }
}

async function searchClientCallsAPI(search) {
  const URL = `${BASE_URL}/api/v1/client_call/filters/?name=${search}`
  const prc_authToken = localStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* searchClientCalls({ search }) {
  try {
    const response = yield call(searchClientCallsAPI, search)
    yield put({
      type: constants.GET_CLIENTCALLS_SUCCESS,
      response: response?.data,
      count: response?.data?.length
    })
  } catch (e) {
    toast.error("Failed to get client calls")
  }
}

async function dateFilterAPI(params) {
  const URL = `${BASE_URL}/api/v1/client_call/filters/?${params}`
  const prc_authToken = localStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* dateFilter({ params }) {
  try {
    const response = yield call(dateFilterAPI, params)
    yield put({
      type: constants.GET_CLIENTCALLS_SUCCESS,
      response: response.data
    })
  } catch (e) {
    yield put({ type: constants.RESET_REQUEST })
    toast.error("Failed to get data for selected date.")
  }
}

async function downloadClientCallsAPI(id) {
  const URL = `${BASE_URL}/api/v1/client_call_download/${id}/download/?type=media`
  const prc_authToken = localStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET",
    responseType: "blob"
  }
  return XHR(URL, options)
}

function* downloadClientCalls({ id, media }) {
  try {
    const response = yield call(downloadClientCallsAPI, id)
    const fileURL = window.URL.createObjectURL(new Blob([response?.data]))
    let alink = document.createElement("a")
    alink.href = fileURL
    alink.download = media.includes("mp4")
      ? `clientcalls_${id}.mp4`
      : `clientcalls_${id}.mp3`
    alink.click()
    toast.success("Client calls downloaded sccessfully")
    yield put(updateAnalyticsAction("client_calls", id, "downloaded"))
    yield put({ type: constants.DOWNLOAD_CLIENT_CALLS_SUCCESS })
  } catch (e) {
    toast.error("Failed to downloaded client calls")
  }
}

export default all([
  takeLatest(constants.GET_CLIENTCALLS, getClientCalls),
  takeLatest(constants.SEARCH_CLIENTCALLS, searchClientCalls),
  takeLatest(constants.DATE_FILETER, dateFilter),
  takeLatest(constants.DOWNLOAD_CLIENT_CALLS, downloadClientCalls)
])
