import React, { useState } from "react"

import PageWrapper from "WebVisual/PageWrapper"
import ClientCall from "WebVisual/Components/ClientCalls"
import ChatWidget from "WebVisual/Components/ChatWidget"

const ClientCalls = () => {
  const [search, setSearch] = useState("")

  return (
    <PageWrapper
      onChange={setSearch}
      title="Client Calls"
      pageBodyHeader={<ChatWidget />}
    >
      <ClientCall search={search} />
    </PageWrapper>
  )
}

export default ClientCalls
