import React from "react"
import "./style.scss"

export const ImagesList = ({ images }) => {
  if (!images?.length) {
    return null
  }

  return (
    <div className="images_wrapper">
      <div className="images">
        {images.map(s => (
          <ImageItem key={s.id} image={s} />
        ))}
      </div>
    </div>
  )
}

const ImageItem = ({ image }) => {
  return (
    <div className="image_item">
      <img className="arrow-icon" src={image.url} />
    </div>
  )
}

export default ImagesList
