import React from "react"

import { AssistantChatMessage, UserChatMessage } from "./components"
import { MESSAGE_TYPE } from "../../constants"

import "./style.scss"

const RenderMessage = ({ message, onAddFeedback, isStreaming }) => {
  if (message.type === MESSAGE_TYPE.USER) {
    return <UserChatMessage message={message} key={message.id} />
  }

  return (
    <AssistantChatMessage
      key={message.id}
      message={message}
      onAddFeedback={onAddFeedback}
      isStreaming={isStreaming}
    />
  )
}

const ChatMessages = ({
  messages,
  onAddFeedback,
  isStreaming,
  chatMessagesContainerRef
}) => {
  return (
    <div className="chat-messages" ref={chatMessagesContainerRef}>
      <div className="chat-messages-content">
        {messages.map((m, index) => (
          <RenderMessage
            message={m}
            key={m.id}
            onAddFeedback={onAddFeedback}
            isStreaming={isStreaming && index === messages.length - 1}
          />
        ))}
      </div>
    </div>
  )
}

export default ChatMessages
