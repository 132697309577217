import React from "react"

import ChatbotIcon from "../../../../../assets/img/chatbotIcon.png"
import RightArrowIcon from "../../../../../assets/img/rightArrowIcon.png"

import "./style.scss"

export const ChatContinueConversationState = ({ onOpenChat }) => {
  return (
    <div className="chat-continue-conversation-state-wrapper">
      <div className="chat-continue-conversation-state" onClick={onOpenChat}>
        <div className="chat-continue-conversation-state-left">
          <img src={ChatbotIcon} alt="Chat" />
          <div className="chat-continue-conversation-state-title">
            PRC Macro Chat
          </div>
          <div className="chat-continue-conversation-state-text">|</div>
        </div>
        <div className="chat-continue-conversation-state-right">
          <div className="chat-continue-conversation-state-text">
            Go Back to the Current Conversation
          </div>
          <div>
            <img src={RightArrowIcon} className="arrowIcon" alt="Right Arrow" />
          </div>
        </div>
      </div>
    </div>
  )
}
