import React, { useState, useEffect, useRef } from "react"
import Images from "utils/Images"
import Pagination from "components/Pagination/Pagination"
import ActivityIndicator from "components/ActivityIndicator"
import { connect } from "react-redux"
import useForm from "utils/useForm"
import moment from "moment"
import { characterEllipsis } from "utils/functions"
import "./style.css"

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  CardText,
  Spinner
} from "reactstrap"

//Action
import { getCategory } from "Containers/ListOfClientCalls/redux/actions"
import {
  getArticles,
  deleteArticles,
  updateArticles,
  updateArticlesFailure,
  publishArticles,
  publishArticlesFailure,
  searchArticles,
  filterArticles,
  searchArticlesFailure,
  filterArticlesFailure,
  deleteArticlesFailure
} from "./redux/actions"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import CButton from "components/Button"

const ListOfArticles = props => {
  const {
    articles,
    categories,
    requesting,
    publishRequesting,
    updateRequesting,
    deleteRequesting,
    searchRequesting,
    filterRequesting,
    searchedArticle,
    filteredArticle
  } = props
  const inputRef = useRef()
  const articleRef = useRef(null)
  const [file, setFile] = useState(false)
  const [offset, setOffset] = useState(0)
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [specificArticle, setSpecificArticle] = useState(false)
  const [isToggle, setIsToggle] = useState(false)
  const [fromDate, setFromDate] = useState(false)
  const [toDate, setToDate] = useState(false)
  // eslint-disable-next-line
  const [logo, setLogo] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("")
  const [categoryName, setCategoryName] = useState(false)
  const [mediaName, setMediaName] = useState(false)
  const [mediaBoolean, setMediaBoolean] = useState(false)
  const [selectPageValue, setSelectPageValue] = useState(1)

  const handleClick = () => setIsOpen(!isOpen)

  const toggleUpdateModal = item => {
    if (updateRequesting) {
      props.updateArticlesFailure(false)
    }
    if (item) {
      setSpecificArticle(item)
    }
    if (isEdit) {
      setIsEdit(false)
    }
    setModal(!modal)
    setState(stateSchema)
    setIsEdited(false)
  }
  const togglePublishModal = () => {
    if (publishRequesting) {
      props.publishArticlesFailure(false)
    }
    setModal1(!modal1)
    setFile(false)
    setState(stateSchema)
  }
  const toggleDeleteModal = item => {
    if (deleteRequesting) {
      setOffset(0)
      props.deleteArticlesFailure(false)
    }
    if (item) {
      setSpecificArticle(item)
    }
    setModal2(!modal2)
  }

  useEffect(() => {
    props.getCategory()
    props.getArticles({ offset: 0 })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    props.getArticles({ offset: offset })
    // eslint-disable-next-line
  }, [offset])

  const stateSchema = {
    title: {
      value: "",
      error: ""
    },
    short_summary: {
      value: "",
      error: ""
    },
    category: {
      value: "",
      error: ""
    },
    date: {
      value: "",
      error: ""
    },

    document: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    title: {
      required: true
      // validator: validator.name
    },
    short_summary: {
      required: true
      // validator: validator.password,
    },
    category: {
      required: true
      // validator: validator.email
    },
    date: {
      required: true
      // validator: validator.email
    },
    document: {
      required: false
      // validator: validator.email
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const getState = state => {
    const userId = localStorage.getItem("userId")
    let formData = new FormData()
    const keys = Object.keys(state)
    const values = Object.values(state)
    if (file) {
      formData.append("document", file)
    }
    formData.append("user", userId)
    if (logo) {
      formData.append("logo", logo)
    }
    keys.forEach((item, i) => {
      if (values[i].value) {
        formData.append(item, values[i].value)
      }
    })
    return formData
  }

  const onEditPress = item => {
    const doc = item?.document.split("/")
    const docName = doc[doc.length - 1]
    setIsEdit(true)
    setModal(!modal)
    handleOnChange("title", item ? item.title : specificArticle.title)
    handleOnChange(
      "short_summary",
      item.short_summary ? item.short_summary : specificArticle.short_summary
    )
    handleOnChange(
      "category",
      item.category ? item.category : specificArticle.category
    )
    handleOnChange("date", item.date ? item.date : specificArticle.date)
    setMediaName(docName)
    setMediaBoolean(false)
    // handleOnChange("document", specificArticle?.document)
  }

  const handleUploadPdf = () => {
    articleRef.current.click()
    return false
  }

  const onPublish = async () => {
    const ApiData = {
      formdata: getState(state)
    }
    props.publishArticles(ApiData, togglePublishModal)
  }

  const onUpdate = async () => {
    const ApiData = {
      id: specificArticle.id,
      formdata: getState(state)
    }
    await props.updateArticles(ApiData, toggleUpdateModal)
    searchArticle("")
  }

  const onDelete = async () => {
    const ApiData = {
      id: specificArticle.id
    }
    await props.deleteArticles(ApiData, toggleDeleteModal)
  }

  const clearFilter = () => {
    setCategoryName(false)
    setSearchText("")
    setFromDate(false)
    setToDate(false)
    setIsToggle(false)
    props.filterArticlesFailure()
  }

  const categoriesName = value => {
    if (value !== categoryName) {
      setCategoryName(value)
    } else {
      setCategoryName(false)
    }
  }

  const onFilterArticles = () => {
    const data = {
      category: categoryName ? categoryName : "",
      dateFrom: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      dateTo: toDate ? moment(toDate).format("YYYY-MM-DD") : ""
    }
    props.filterArticles(data, setIsToggle)
  }

  const searchArticle = name => {
    if (name.length) {
      props.searchArticles({ name })
    } else {
      props.searchArticlesFailure()
      setOffset(0)
      props.getArticles({ offset: 0 })
    }
    setSearchText(name)
  }

  // const onPressSearch = () => {
  //   const data = {
  //     name: searchText
  //   }
  //   props.searchArticles(data)
  // }
  return (
    <div className="ml-lg-5 mr-lg-5 mr-3 ml-3">
      <Row>
        <Col md="12">
          <Card className="card-plain">
            <CardHeader>
              <Row
                className="d-md-flex m-0"
                style={{
                  justifyContent: "space-between"
                }}
              >
                <CardTitle
                  tag="h4"
                  style={{
                    fontWeight: "400",
                    fontSize: "20px",
                    lineHeight: "25px",
                    color: "#4A5981",
                    marginTop: "24px"
                  }}
                >
                  List of Articles
                </CardTitle>
                <Col lg="8">
                  <div
                    className="header d-sm-flex"
                    style={{
                      alignItems: "center"
                    }}
                  >
                    <InputGroup
                      className="no-border"
                      style={{
                        borderRadius: "8px",
                        marginTop: "10px",
                        marginRight: "20px"
                      }}
                    >
                      <Input
                        defaultValue=""
                        value={searchText}
                        placeholder="Search..."
                        type="text"
                        style={{
                          backgroundColor: "white",
                          height: "46px"
                        }}
                        onChange={e => searchArticle(e.target.value)}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          style={{ backgroundColor: "white" }}
                          // onClick={() => onPressSearch()}
                        >
                          {searchRequesting ? (
                            <Spinner size="sm" />
                          ) : (
                            <img
                              style={{
                                height: "17px",
                                width: "17px"
                              }}
                              alt=""
                              src={Images.CSearch}
                            />
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>

                    <Dropdown
                      isOpen={isToggle}
                      toggle={() => setIsToggle(!isToggle)}
                    >
                      <DropdownToggle
                        aria-haspopup={true}
                        color="default"
                        data-toggle="dropdown"
                        id="navbarDropdownMenuLink"
                        nav
                        style={{
                          paddingLeft: 0,
                          width: 121
                        }}
                      >
                        <Button
                          onClick={() => {
                            setIsToggle(!isToggle)
                          }}
                          className=" text-capitalize"
                          style={{
                            backgroundColor: "#fff",
                            height: "46px",
                            color: "#F01716",
                            border: "1px solid #EAEAEA",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: 108,
                            borderRadius: "8px"
                          }}
                        >
                          <img
                            alt=""
                            style={{
                              height: "16px",
                              width: "19px",
                              borderRadius: 0,
                              marginRight: "4px"
                            }}
                            src={Images.filterD}
                          />
                          Filter
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu
                        persist
                        aria-labelledby="navbarDropdownMenuLink"
                        right
                        style={{
                          // backgroundColor: "pink",
                          width: 300,
                          right: "10%"
                          // height: 500
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            fontSize: 15,
                            fontWeight: "bold",
                            padding: 15
                          }}
                        >
                          Filter by date
                        </div>

                        <div
                          style={{
                            color: "black",
                            fontSize: 12,
                            fontWeight: "bold",
                            padding: 15
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexGrow: 1,
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignContent: "center"
                            }}
                          >
                            <div
                              style={{ width: "48%" }}
                              onClick={() => handleClick()}
                            >
                              <p style={{ fontSize: 15 }}>From</p>
                              <div
                                style={{
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: 6
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    paddingLeft: 15,
                                    paddingRight: 16
                                  }}
                                >
                                  <img
                                    src={Images.calander}
                                    style={{
                                      width: 16,
                                      height: 15,
                                      marginRight: 6,
                                      borderRadius: 0
                                      // objectFit: "contain"
                                    }}
                                    alt=""
                                  />

                                  <DatePicker
                                    placeholderText={"From date"}
                                    className="form-Data"
                                    selected={fromDate && fromDate}
                                    onChange={date => setFromDate(date)}
                                    dropdownMode="select"
                                    dateFormat="d MMM, yyyy"
                                    // todayButton="Ma"
                                    closeOnScroll={true}
                                    locale="hu"
                                    // calendarStartDay={1}
                                  />
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "48%" }}>
                              <p style={{ fontSize: 15 }}>To</p>
                              <div
                                style={{
                                  backgroundColor: "#F2F2F2",
                                  borderRadius: 6
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    paddingLeft: 15,
                                    paddingRight: 16
                                  }}
                                >
                                  <img
                                    src={Images.calander}
                                    style={{
                                      width: 16,
                                      height: 15,
                                      marginRight: 6,
                                      borderRadius: 0
                                    }}
                                    alt=""
                                  />
                                  <DatePicker
                                    className="form-Data"
                                    placeholderText={"To date"}
                                    selected={toDate && toDate}
                                    onChange={date => setToDate(date)}
                                    minDate={fromDate}
                                    dropdownMode="select"
                                    dateFormat="d MMM, yyyy"
                                    // todayButton="Ma"
                                    closeOnScroll={true}
                                    locale="hu"
                                    // calendarStartDay={1}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <DropdownItem divider />
                        <div
                          style={{
                            color: "black",
                            fontSize: 15,
                            fontWeight: "bold",
                            padding: 15
                          }}
                        >
                          Filter by Category
                        </div>
                        {categories &&
                          categories.category.map((item, i) => {
                            return (
                              <div
                                style={{
                                  fontSize: 15,
                                  marginLeft: 16
                                }}
                              >
                                <FormGroup check>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                      // marginLeft: 40
                                    }}
                                  >
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        checked={item === categoryName}
                                        onChange={() => {
                                          categoriesName(item)
                                          // setCategoryName(item)
                                          // setCheck(!check)
                                        }}
                                      />
                                      <span
                                        className="form-check-sign"
                                        style={{
                                          height: "10px",
                                          backgroundColor: "#3A0F7D"
                                        }}
                                      />
                                      <span
                                        style={{
                                          color: "#000000",
                                          fontWeight: "400",
                                          fontSize: 15,
                                          textTransform: "capitalize"
                                        }}
                                      >
                                        {item}
                                      </span>
                                    </Label>
                                  </div>
                                </FormGroup>
                              </div>
                            )
                          })}
                        <DropdownItem divider />
                        <div
                          style={{
                            color: "#838181",
                            fontFamily: "Khula",
                            fontSize: 15,
                            padding: 10
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <Button
                              style={{
                                backgroundColor: "#FFF",
                                height: 37,
                                borderRadius: "6px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                whiteSpace: "nowrap",
                                color: "#F01716",
                                border: "1px solid"
                              }}
                              className=" text-capitalize"
                              onClick={onFilterArticles}
                            >
                              {filterRequesting ? (
                                <div
                                  style={{
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    width: "100%"
                                  }}
                                >
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </div>
                              ) : (
                                "Apply Filter"
                              )}
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "#F1F1F1",
                                height: 37,
                                borderRadius: "6px",
                                marginLeft: 10,
                                display: "flex",
                                // justifyContent: "center",
                                alignItems: "center",
                                whiteSpace: "nowrap",
                                color: "#4A5981"
                              }}
                              onClick={clearFilter}
                              className=" text-capitalize"
                            >
                              <img
                                style={{
                                  height: 16,
                                  width: 15,
                                  marginRight: 5
                                }}
                                alt=""
                                src={Images.clearLoader}
                              />
                              Clear
                            </Button>
                          </div>
                        </div>
                      </DropdownMenu>
                    </Dropdown>

                    {/* <Button
                      className="mr-md-n2 text-capitalize"
                      onClick={() => togglePublishModal()}
                      style={{
                        backgroundColor: "#F01716",
                        height: 46,
                        borderRadius: "6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        whiteSpace: "nowrap"
                      }}
                    >
                      Publish Article
                    </Button> */}

                    <CButton
                      onClick={togglePublishModal}
                      className="btnStyle"
                      btnStyle={{
                        backgroundColor: "#F01716",
                        height: 46,
                        borderRadius: "6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        color: "white",
                        border: "none",
                        fontWeight: 600,
                        fontSize: "14px",
                        padding: "14px 37px 14px"
                      }}
                      title={"Publish Article"}
                    />
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody style={{ marginBottom: "20px" }}>
              <Row className=" d-flex  px-3">
                {searchedArticle && searchText ? (
                  searchedArticle.length > 0 ? (
                    searchedArticle.map(item => {
                      const doc =
                        item &&
                        item.document != null &&
                        item?.document.split("/")
                      const docName = doc[doc.length - 1]
                      return (
                        <Col sm="6" className="mb-5">
                          <Card body className="p-0">
                            <CardHeader
                              style={{
                                backgroundColor: "#000000",
                                height: 53,
                                borderRadius: "8px 8px 0px 0px"
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "0px",
                                  marginTop: -9
                                }}
                                src={Images.cardLogo}
                              />
                            </CardHeader>
                            <CardTitle>
                              <p
                                style={{
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  margin: "16px 13px 16px 13px"
                                }}
                              >
                                {item.title}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "white",
                                  height: 39,
                                  width: 149,
                                  marginLeft: 13,
                                  border: "1px solid #4A5981",
                                  borderRadius: "4px"
                                }}
                              >
                                <img
                                  alt=""
                                  style={{
                                    height: "15px",
                                    width: "11px",
                                    borderRadius: "0px",
                                    margin: "0 13px"
                                  }}
                                  src={Images.pdfIcon}
                                />
                                <p
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    color: "#4A5981",
                                    margin: "0"
                                  }}
                                >
                                  {/* {item.title}.pdf */}
                                  {item?.document
                                    ? characterEllipsis(docName, 6)
                                    : "No File."}
                                </p>
                              </div>
                            </CardTitle>
                            <CardText>
                              <p
                                style={{
                                  marginLeft: "13px",
                                  color: "#000000",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "21px"
                                }}
                              >
                                {item.short_summary}
                              </p>

                              <Row
                                style={{ display: "flex", marginLeft: "13px" }}
                              >
                                <p
                                  style={{
                                    color: "#939393",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "15px"
                                  }}
                                >
                                  Category:
                                </p>
                                <p
                                  style={{
                                    color: "#000000",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "15px",
                                    marginLeft: "4px",
                                    textTransform: "capitalize"
                                  }}
                                >
                                  {item.category}
                                </p>
                                <p
                                  style={{
                                    color: "#939393",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "15px",
                                    marginLeft: "31px"
                                  }}
                                >
                                  {item.date}
                                </p>
                              </Row>
                            </CardText>
                            <Row
                              style={{
                                border: "1px solid #EBEBEB",
                                width: "95%",
                                margin: "-10px 13px 1px 13px "
                              }}
                            ></Row>
                            <div
                              style={{
                                display: "flex",
                                marginLeft: "13px",
                                marginBottom: "10px"
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setSpecificArticle(item)
                                  // toggle(item)
                                  onEditPress(item)
                                }}
                                className=" text-capitalize"
                                style={{
                                  backgroundColor: "#fff",
                                  height: "36px",
                                  color: "#F01716",
                                  fontSize: "12px",
                                  fontWeight: "700px",
                                  border: "1px solid #F01716",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "6px",
                                  width: "78px"
                                }}
                              >
                                <img
                                  alt=""
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    marginTop: "-2px",
                                    marginRight: "4px"
                                  }}
                                  src={Images.edit}
                                />
                                Edit
                              </Button>
                              <Button
                                className="btn-link text-capitalize"
                                onClick={() => {
                                  toggleDeleteModal(item)
                                }}
                                style={{
                                  color: "#F01716",
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <img
                                  alt=""
                                  style={{
                                    height: 12,
                                    width: 10,
                                    marginRight: "5px",
                                    marginTop: "-2px",
                                    borderRadius: "0px"
                                  }}
                                  src={Images.delete}
                                />
                                Delete
                              </Button>
                            </div>
                          </Card>
                        </Col>
                      )
                    })
                  ) : (
                    <Col
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center"
                      }}
                    >
                      <p style={{ textAlign: "center" }}>No record found.</p>
                    </Col>
                  )
                ) : filteredArticle ? (
                  filteredArticle.length > 0 ? (
                    filteredArticle.map(item => {
                      const doc =
                        item &&
                        item.document != null &&
                        item?.document.split("/")
                      const docName = doc[doc.length - 1]
                      return (
                        <Col sm="6" className="mb-5">
                          <Card body className="p-0">
                            <CardHeader
                              style={{
                                backgroundColor: "#000000",
                                height: 53,
                                borderRadius: "8px 8px 0px 0px"
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "0px",
                                  marginTop: -9
                                }}
                                src={Images.cardLogo}
                              />
                            </CardHeader>
                            <CardTitle>
                              <p
                                style={{
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  margin: "16px 13px 16px 13px"
                                }}
                              >
                                {item.title}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "white",
                                  height: 39,
                                  width: 149,
                                  marginLeft: 13,
                                  border: "1px solid #4A5981",
                                  borderRadius: "4px"
                                }}
                              >
                                <img
                                  alt=""
                                  style={{
                                    height: "15px",
                                    width: "11px",
                                    borderRadius: "0px",
                                    margin: "0 13px"
                                  }}
                                  src={Images.pdfIcon}
                                />
                                <p
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    color: "#4A5981",
                                    margin: "0px"
                                  }}
                                >
                                  {/* {item.title}.pdf */}

                                  {item?.document
                                    ? characterEllipsis(docName, 6)
                                    : "No File."}
                                </p>
                              </div>
                            </CardTitle>
                            <CardText>
                              <p
                                className="card-text-inner"
                                style={{
                                  marginLeft: "13px",
                                  color: "#000000",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "44px"
                                }}
                              >
                                {item.short_summary}
                              </p>

                              <Row
                                style={{ display: "flex", marginLeft: "13px" }}
                              >
                                <p
                                  style={{
                                    color: "#939393",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "15px"
                                  }}
                                >
                                  Category:
                                </p>
                                <p
                                  style={{
                                    color: "#000000",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    lineHeight: "15px",
                                    marginLeft: "4px",
                                    textTransform: "capitalize"
                                  }}
                                >
                                  {item.category}
                                </p>
                                <p
                                  style={{
                                    color: "#939393",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "15px",
                                    marginLeft: "31px"
                                  }}
                                >
                                  {item.date}
                                </p>
                              </Row>
                            </CardText>
                            <Row
                              style={{
                                border: "1px solid #EBEBEB",
                                width: "95%",
                                margin: "-10px 13px 1px 13px "
                              }}
                            ></Row>
                            <div
                              style={{
                                display: "flex",
                                marginLeft: "13px",
                                marginBottom: "10px"
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setSpecificArticle(item)
                                  // toggle(item)
                                  onEditPress(item)
                                }}
                                className=" text-capitalize"
                                style={{
                                  backgroundColor: "#fff",
                                  height: "36px",
                                  color: "#F01716",
                                  fontSize: "12px",
                                  fontWeight: "700px",
                                  border: "1px solid #F01716",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "6px",
                                  width: "78px"
                                }}
                              >
                                <img
                                  alt=""
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    marginTop: "-2px",
                                    marginRight: "4px"
                                  }}
                                  src={Images.edit}
                                />
                                Edit
                              </Button>
                              <Button
                                className="btn-link text-capitalize"
                                onClick={() => {
                                  toggleDeleteModal(item)
                                }}
                                style={{
                                  color: "#F01716",
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <img
                                  alt=""
                                  style={{
                                    height: 12,
                                    width: 10,
                                    marginRight: "5px",
                                    marginTop: "-2px",
                                    borderRadius: "0px"
                                  }}
                                  src={Images.delete}
                                />
                                Delete
                              </Button>
                            </div>
                          </Card>
                        </Col>
                      )
                    })
                  ) : (
                    <Col
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center"
                      }}
                    >
                      <p style={{ textAlign: "center" }}>No record found.</p>
                    </Col>
                  )
                ) : articles && articles?.results.length > 0 ? (
                  articles.results.map(item => {
                    const doc =
                      item && item.document != null && item?.document.split("/")
                    const docName = doc[doc.length - 1]
                    return (
                      <Col sm="6" className="mb-5">
                        <Card body className="p-0">
                          <CardHeader
                            style={{
                              backgroundColor: "#000000",
                              height: 53,
                              borderRadius: "8px 8px 0px 0px"
                            }}
                          >
                            <img
                              alt=""
                              style={{
                                height: "40px",
                                width: "40px",
                                borderRadius: "0px",
                                marginTop: -9
                              }}
                              src={Images.cardLogo}
                            />
                          </CardHeader>
                          <CardTitle>
                            <p
                              style={{
                                fontWeight: "600",
                                fontSize: "16px",
                                margin: "16px 13px 16px 13px"
                              }}
                            >
                              {item.title}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: "white",
                                height: 39,
                                width: 149,
                                marginLeft: 13,
                                border: "1px solid #4A5981",
                                borderRadius: "4px"
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "15px",
                                  width: "11px",
                                  borderRadius: "0px",
                                  margin: "0 13px"
                                }}
                                src={Images.pdfIcon}
                              />
                              <p
                                style={{
                                  fontWeight: "400",
                                  fontSize: "12px",
                                  lineHeight: "15px",
                                  color: "#4A5981",
                                  margin: "0",
                                  whiteSpace: "nowrap",
                                  width: "65%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }}
                              >
                                {item?.document
                                  ? characterEllipsis(docName, 6)
                                  : "No File."}
                              </p>
                            </div>
                          </CardTitle>
                          <CardText>
                            <p
                              style={{
                                marginLeft: "13px",
                                color: "#000000",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "21px"
                              }}
                            >
                              {item.short_summary}
                            </p>

                            <Row
                              style={{ display: "flex", marginLeft: "13px" }}
                            >
                              <p
                                style={{
                                  color: "#939393",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  lineHeight: "15px"
                                }}
                              >
                                Category:
                              </p>
                              <p
                                style={{
                                  color: "#000000",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  lineHeight: "15px",
                                  marginLeft: "4px",
                                  textTransform: "capitalize"
                                }}
                              >
                                {item.category}
                              </p>
                              <p
                                style={{
                                  color: "#939393",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineHeight: "15px",
                                  marginLeft: "31px"
                                }}
                              >
                                {item.date}
                              </p>
                            </Row>
                          </CardText>
                          <Row
                            style={{
                              border: "1px solid #EBEBEB",
                              width: "95%",
                              margin: "-10px 13px 1px 13px "
                            }}
                          ></Row>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: "13px",
                              marginBottom: "10px"
                            }}
                          >
                            <Button
                              onClick={() => {
                                setSpecificArticle(item)
                                onEditPress(item)
                              }}
                              className=" text-capitalize"
                              style={{
                                backgroundColor: "#fff",
                                height: "36px",
                                color: "#F01716",
                                fontSize: "12px",
                                fontWeight: "700px",
                                border: "1px solid #F01716",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                width: "78px"
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  marginTop: "-2px",
                                  marginRight: "4px"
                                }}
                                src={Images.edit}
                              />
                              Edit
                            </Button>
                            <Button
                              className="btn-link text-capitalize"
                              onClick={() => {
                                toggleDeleteModal(item)
                              }}
                              style={{
                                color: "#F01716",
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: 12,
                                  width: 10,
                                  marginRight: "5px",
                                  marginTop: "-2px",
                                  borderRadius: "0px"
                                }}
                                src={Images.delete}
                              />
                              Delete
                            </Button>
                          </div>
                        </Card>
                      </Col>
                    )
                  })
                ) : (
                  <ActivityIndicator
                    requesting={requesting}
                    data={articles && articles.results.length > 0}
                    message={"No article found."}
                  />
                )}
              </Row>
            </CardBody>
            {articles &&
            articles.count > 10 &&
            !(searchedArticle || filteredArticle) ? (
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <Pagination
                  totalCount={articles && articles.count}
                  offset={offset}
                  setOffset={setOffset}
                  selectPageValue={selectPageValue}
                  setSelectPageValue={setSelectPageValue}
                />
              </Row>
            ) : null}
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={modal}
        toggle={toggleUpdateModal}
        style={{ maxWidth: "762px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <label
            className="p-0 m-0"
            style={{ fontSize: "16px", fontWeight: "700", color: "##000000" }}
          >
            Edit Article
          </label>
          <img
            alt=""
            onClick={toggleUpdateModal}
            style={{
              height: 15,
              width: 15,
              marginLeft: "20px"
            }}
            src={Images.cross}
          />
        </div>

        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Row className="mt-md-4" style={{ justifyContent: "center" }}>
            <Col sm="12">
              <Row style={{ justifyContent: "center" }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}
                    sm="6"
                  >
                    TITLE
                  </Label>
                  <FormGroup>
                    <Input
                      defaultValue={state.title.value}
                      // value={isEdit ? null : specificArticle.name}
                      placeholder=""
                      type="text"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "52px",
                        color: "#000"
                      }}
                      // disabled={!isEdit}
                      onChange={e => handleOnChange("title", e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ justifyContent: "center", marginTop: 10 }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}
                    sm="6"
                  >
                    SHORT SUMMARY
                  </Label>
                  <FormGroup>
                    <Input
                      defaultValue={state.short_summary.value}
                      // value={isEdit ? null : specificArticle.short_summary}
                      placeholder=""
                      type="textarea"
                      style={{
                        backgroundColor: "#F2F2F2",
                        color: "#000"
                      }}
                      // disabled={!isEdit}
                      onChange={e =>
                        handleOnChange("short_summary", e.target.value)
                      }
                      maxlength="200"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ display: "flex", marginTop: 10 }}>
                <Col>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px"
                      }}
                      sm="6"
                    >
                      CATEGORY
                    </Label>
                    <div className="customselect1">
                      <Input
                        defaultValue={state.category.value}
                        // value={isEdit ? null : specificArticle.category}
                        id="exampleSelect"
                        name="select"
                        type="select"
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: "52px",
                          color: "#000",
                          border: "transparent"
                        }}
                        // disabled={!isEdit}
                        onChange={e =>
                          handleOnChange("category", e.target.value)
                        }
                      >
                        {categories &&
                          categories?.category?.map((item, i) => {
                            return <option>{item}</option>
                          })}
                      </Input>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px"
                      }}
                      sm="6"
                    >
                      DATE
                    </Label>
                    <Input
                      defaultValue={state.date.value}
                      // value={isEdit ? null : specificArticle.date}
                      placeholder=""
                      type="date"
                      style={{
                        backgroundColor: "#F2F2F2",
                        color: "#000",
                        height: "52px"
                      }}
                      // disabled={!isEdit}
                      onChange={e => handleOnChange("date", e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10
                }}
              >
                <div style={{ display: "none" }}>
                  <input
                    type="file"
                    accept=".pdf"
                    ref={inputRef}
                    value=""
                    onChange={e => {
                      e.preventDefault()
                      setFile(e.target.files[0])
                      handleOnChange("document", e.target.files[0])
                      setIsEdited(false)
                    }}
                  />
                </div>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}
                    sm="6"
                  >
                    UPLOAD FILE
                  </Label>
                  <div
                    style={{
                      background: "#FFFFFF",
                      height: "60px",
                      width: "100%",
                      borderRadius: "6px",
                      border: "1px dotted red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={() => inputRef.current.click()}
                  >
                    {isEdit && mediaName ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#000",
                          margin: 0,
                          padding: 0
                        }}
                      >
                        {characterEllipsis(mediaName, 6)}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                          onClick={() => {
                            setIsEdit(!isEdit)
                            setIsEdited(true)
                            // onEditPress()
                            handleOnChange("document", "")
                            setMediaBoolean(true)
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: "black",
                              width: 1,
                              height: 30,
                              marginTop: 15,
                              marginLeft: 12
                            }}
                          ></p>
                          <img
                            alt=""
                            style={{
                              height: 15,
                              width: 15,
                              marginLeft: "20px"
                            }}
                            src={Images.cross}
                          />
                        </div>
                      </p>
                    ) : state.document?.value?.name ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F01716",
                          margin: 0,
                          padding: 0
                        }}
                      >
                        {characterEllipsis(state.document.value.name, 6)}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                          onClick={() => {
                            setFile(false)
                            handleOnChange("document", "")
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: "black",
                              width: 1,
                              height: 30,
                              marginTop: 15,
                              marginLeft: 12
                            }}
                          ></p>
                          <img
                            alt=""
                            style={{
                              height: 15,
                              width: 15,
                              marginLeft: "20px"
                            }}
                            src={Images.cross}
                          />
                        </div>
                      </p>
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F01716",
                          margin: 0,
                          padding: 0,
                          cursor: "pointer"
                        }}
                      >
                        + Upload Article File
                      </p>
                    )}
                  </div>
                </Col>
              </Row>

              <hr />
              <Row style={{ justifyContent: "center", marginBottom: "25px" }}>
                <Button
                  onClick={toggleUpdateModal}
                  className="mr-lg-3"
                  style={{
                    backgroundColor: "#fff",
                    color: "#F01716",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#F01716",
                    color: "#FFFF",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50
                  }}
                  onClick={onUpdate}
                  disabled={
                    updateRequesting ||
                    disable ||
                    isEdited ||
                    (!file && mediaName && mediaBoolean) ||
                    (mediaName && mediaBoolean) ||
                    file
                      ? !file?.name?.includes(".pdf")
                      : false
                  }
                >
                  {updateRequesting ? <Spinner size="sm" /> : "Save Changes"}
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        isOpen={modal1}
        toggle={togglePublishModal}
        style={{ maxWidth: "762px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px"
          }}
        >
          <label
            className="p-0 m-0"
            style={{ fontSize: "16px", fontWeight: "700", color: "##000000" }}
          >
            Publish Article
          </label>
          <img
            alt=""
            onClick={togglePublishModal}
            style={{
              height: 15,
              width: 15,
              marginLeft: "20px"
            }}
            src={Images.cross}
          />
        </div>

        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Row className="mt-md-4" style={{ justifyContent: "center" }}>
            <Col sm="12">
              <Row style={{ justifyContent: "center" }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}
                    sm="6"
                  >
                    TITLE
                  </Label>
                  <FormGroup>
                    <Input
                      placeholder=""
                      type="text"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "52px"
                      }}
                      onChange={e => handleOnChange("title", e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ justifyContent: "center", marginTop: 10 }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}
                    sm="6"
                  >
                    SHORT SUMMARY
                  </Label>
                  <FormGroup>
                    <Input
                      placeholder=""
                      type="textarea"
                      style={{
                        backgroundColor: "#F2F2F2"
                      }}
                      maxlength="200"
                      onChange={e =>
                        handleOnChange("short_summary", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ display: "flex", marginTop: 10 }}>
                <Col>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px"
                      }}
                      sm="6"
                    >
                      CATEGORY
                    </Label>
                    <div className="customselect1">
                      <Input
                        id="exampleSelect"
                        placeholder="ieiri"
                        name="select"
                        type="select"
                        style={{
                          backgroundColor: "#F2F2F2",
                          height: "52px",
                          border: "transparent"
                        }}
                        onChange={e =>
                          handleOnChange(
                            "category",
                            e.target.value === "Select Category"
                              ? ""
                              : e.target.value
                          )
                        }
                      >
                        <option>Select Category</option>
                        {categories &&
                          categories?.category?.map((item, i) => {
                            return <option>{item}</option>
                          })}
                      </Input>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label
                      style={{
                        color: "#000000",
                        padding: "0px",
                        marginBottom: "7px",
                        fontWeight: "600",
                        fontSize: "12px"
                      }}
                      sm="6"
                    >
                      DATE
                    </Label>
                    <Input
                      placeholder=""
                      type="date"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: "52px"
                      }}
                      onChange={e => handleOnChange("date", e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10
                }}
              >
                <div style={{ display: "none" }}>
                  <input
                    type="file"
                    accept=".pdf"
                    ref={articleRef}
                    value=""
                    onChange={e => {
                      setFile(e.target.files[0])
                      handleOnChange("document", e.target.files[0])
                    }}
                  />
                </div>
                <Col md="12">
                  <Label
                    style={{
                      color: "#000000",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}
                    sm="6"
                  >
                    UPLOAD FILE
                  </Label>

                  <div
                    style={{
                      background: "rgba(240, 23, 22, 0.2)",
                      height: "60px",
                      width: "100%",
                      borderRadius: "6px",
                      border: "1px dotted red",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={handleUploadPdf}
                  >
                    {state.document.value?.name ? (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F01716",
                          margin: 0,
                          padding: 0
                        }}
                      >
                        {state.document.value.name}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                          onClick={() => {
                            setFile(false)
                            handleOnChange("document", "")
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: "black",
                              width: 1,
                              height: 30,
                              marginTop: 15,
                              marginLeft: 12
                            }}
                          ></p>
                          <img
                            alt=""
                            style={{
                              height: 15,
                              width: 15,
                              marginLeft: "20px"
                            }}
                            src={Images.cross}
                          />
                        </div>
                      </p>
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#F01716",
                          margin: 0,
                          padding: 0,
                          cursor: "pointer"
                        }}
                      >
                        + Upload Article File
                      </p>
                    )}
                  </div>
                  <p
                    style={{
                      color: "rgba(147, 147, 147, 1)",
                      fontSize: "12px",
                      marginTop: 5
                    }}
                  >
                    *Please upload file type PDF
                  </p>
                </Col>
              </Row>

              <Row style={{ justifyContent: "center", marginBottom: "25px" }}>
                <Button
                  className="mr-lg-3"
                  onClick={togglePublishModal}
                  style={{
                    backgroundColor: "#fff",
                    color: "#F01716",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#F01716",
                    color: "#FFFF",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50
                  }}
                  onClick={onPublish}
                  disabled={disable || !file || !file.name.includes(".pdf")}
                >
                  {publishRequesting ? <Spinner size="sm" /> : "publish"}
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal isOpen={modal2} toggle={toggleDeleteModal}>
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img
            alt=""
            style={{
              height: 45,
              width: 38,
              marginTop: "40px"
            }}
            src={Images.deleteDark}
          />
        </div>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
            fontSize: "22px",
            color: "#4A5981"
          }}
        >
          Delete Article?
        </p>
        <p
          style={{
            marginTop: "-25px",
            padding: "35px",
            textAlign: "center",
            fontSize: "15px",
            color: "#000000"
          }}
        >
          Are you sure, you want to delete this article? All the data related to
          this article will get lost if you delete it.
        </p>

        <Row style={{ justifyContent: "center", marginBottom: "25px" }}>
          <Button
            className="mr-lg-3"
            onClick={toggleDeleteModal}
            style={{
              backgroundColor: "#fff",
              color: "#F01716",
              border: "1px solid #F01716",
              borderRadius: "8px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#F01716",
              color: "#FFFF",
              border: "1px solid #F01716",
              borderRadius: "8px",
              width: "151px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50
            }}
            onClick={onDelete}
          >
            {deleteRequesting ? <Spinner size="sm" /> : "Yes, Delete"}
          </Button>
        </Row>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => ({
  articles: state.ArticlesReducer.articles,
  requesting: state.ArticlesReducer.requesting,
  categories: state.getAllClientCallsReducer.categories,
  publishRequesting: state.ArticlesReducer.publishRequesting,
  updateRequesting: state.ArticlesReducer.updateRequesting,
  deleteRequesting: state.ArticlesReducer.deleteRequesting,
  filterRequesting: state.ArticlesReducer.filterRequesting,
  searchRequesting: state.ArticlesReducer.searchRequesting,
  filteredArticle: state.ArticlesReducer.filteredArticle,
  searchedArticle: state.ArticlesReducer.searchedArticle
})
const mapDispatchToProps = dispatch => ({
  getArticles: data => dispatch(getArticles(data)),
  getCategory: data => dispatch(getCategory(data)),
  deleteArticles: (data, toggle) => dispatch(deleteArticles(data, toggle)),
  updateArticles: (data, toggle) => dispatch(updateArticles(data, toggle)),
  publishArticles: (data, toggle) => dispatch(publishArticles(data, toggle)),
  searchArticles: (data, setIsToggle) =>
    dispatch(searchArticles(data, setIsToggle)),
  filterArticles: (data, setIsToggle) =>
    dispatch(filterArticles(data, setIsToggle)),
  publishArticlesFailure: data => dispatch(publishArticlesFailure(data)),
  updateArticlesFailure: data => dispatch(updateArticlesFailure(data)),
  deleteArticlesFailure: data => dispatch(deleteArticlesFailure(data)),
  filterArticlesFailure: data => dispatch(filterArticlesFailure(data)),
  searchArticlesFailure: data => dispatch(searchArticlesFailure(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(ListOfArticles)
