import React, { useState } from "react"

import { Button, Modal, ModalBody } from "reactstrap"
import CopyConversationButton from "../CopyConversationButton"

import "./style.scss"

const AddNewChatButton = ({ onAddNewChat, onCopyConversation, isDisabled }) => {
  const [modal, setModal] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const toggle = () => setModal(!modal)

  const handleAddNewChat = () => {
    onAddNewChat()
    toggle()
  }

  const handleCopyConversation = () => {
    onCopyConversation()
  }

  return (
    <div className="new-chat-button-wrapper">
      <Button
        className="text-capitalize new-chat-button"
        color="danger"
        onClick={toggle}
        disabled={isDisabled}
        outline
      >
        + Start New Chat
      </Button>
      <Modal isOpen={modal} toggle={toggle} backdrop={true} centered={true}>
        <ModalBody className="new-chat-modal">
          <div className="new-chat-modal-header">
            Do you want to copy your conversation before you start a new Chat?
          </div>
          <div className="new-chat-modal-content">
            When you start a new chat, you will end the current chat. To save
            your conversation, please copy to your clipboard and save the
            conversation to a document or email.
          </div>
          <div
            className={`new-chat-modal-copy-conversation ${
              isCopied ? "copied" : ""
            }`}
          >
            <CopyConversationButton
              onChangeCopyState={setIsCopied}
              onCopyConversation={handleCopyConversation}
            />
          </div>
          <div className="new-chat-modal-footer">
            <Button
              className="text-capitalize cancel-button"
              onClick={toggle}
              color="danger"
              outline
            >
              Cancel
            </Button>
            <Button
              className="text-capitalize submit-button"
              color="danger"
              onClick={handleAddNewChat}
            >
              Start New Chat
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AddNewChatButton
