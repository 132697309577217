import { all, call, put, takeLatest } from "redux-saga/effects"

import { toast } from "react-hot-toast"
// config
import { BASE_URL } from "config/app"

// utils
import XHR from "utils/XHR"

// types
import {
  GET_ALL_CLIENT_CALLS,
  CREATE_CLIENT_CALL,
  UPDATE_CLIENT_CALL,
  DELETE_CLIENT_CALL,
  FILTER_CLIENT_CALL,
  SEARCH_CLIENT_CALL,
  GET_CATEGORY
} from "./types"

// actions
import {
  // toggleAddModal,
  // toggleEditModal,
  getAllClientCalls,
  getAllClientCallsSuccess,
  getAllClientCallsFailure,
  createClientCallSuccess,
  createClientCallFailure,
  updateClientCallSuccess,
  updateClientCallFailure,
  deleteClientCallSuccess,
  deleteClientCallFailure,
  filterClientCallSuccess,
  filterClientCallFailure,
  searchClientCallSuccess,
  getCategoryFailure,
  getCategorySuccess,
  searchClientCallFailure
} from "./actions"

async function getAllClientCallsAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/client_call/?limit=10&offset=${data.offset}`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getAllClientCall({ data }) {
  try {
    const response = yield call(getAllClientCallsAPI, data)
    yield put(getAllClientCallsSuccess(response?.data))
  } catch (e) {
    let err = e?.response?.data?.non_field_errors
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(getAllClientCallsFailure("Connection Error"))
    } else {
      toast.error(err)
      yield put(getAllClientCallsFailure(err))
    }
  }
}

async function createClientCallsAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/client_call/`
  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${prc_authToken}`
    },
    method: "POST",
    data: data.formdata
  }

  return XHR(URL, options)
}

function* createClientCall({ data, toggle }) {
  try {
    const response = yield call(createClientCallsAPI, data)
    yield put(createClientCallSuccess(response?.data))
    yield put(getAllClientCalls({ offset: 0 }))
    toggle()
    toast.success("Client call created successfully")
  } catch (e) {
    let err = e?.response?.data
    // e?.response?.data?.non_field_errors
    //   ? e.response.data.non_field_errors
    //   : e?.response?.data["detail"]
    //   ? e.response.data["detail"][0]
    //     ? e.response.data["detail"][0]
    //     : e.response.data["detail"]
    //   : e?.response?.data["category"]
    //   ? e.response.data["category"][0]
    //     ? e.response.data["category"][0]
    //     : e.response.data["category"]
    //   : e.response.data["logo"]
    //   ? "No logo was submitted."
    //   : "Something went wrong."
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(createClientCallFailure("Connection Error"))
    } else if (e.response.data["detail"] === "Invalid token.") {
      toast.error("Invalid token.")
      // yield put(createClientCallFailure("Invalid token."))
    } else {
      // toast.error(err)
      yield put(createClientCallFailure(err))
    }
  }
}

async function updateClientCallsAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/client_call/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "multipart/form-data"
    },
    method: "PATCH",
    data: data.formdata
  }

  return XHR(URL, options)
}

function* updateClientCall({ data, toggle }) {
  try {
    const response = yield call(updateClientCallsAPI, data)
    yield put(updateClientCallSuccess(response?.data))
    yield put(getAllClientCalls({ offset: 0 }))
    toggle()
    toast.success("Client call updated successfully")
  } catch (e) {
    let err = e?.response?.data?.non_field_errors
      ? e.response.data.non_field_errors
      : e?.response?.data?.detail
      ? e.response.data.detail
      : "Something went wrong."
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(updateClientCallFailure("Connection Error"))
    } else {
      toast.error(err)
      yield put(updateClientCallFailure(err))
    }
  }
}

async function deleteClientCallsAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/client_call/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "application/json"
    },
    method: "DELETE"
  }

  return XHR(URL, options)
}

function* deleteClientCall({ data, toggle }) {
  try {
    const response = yield call(deleteClientCallsAPI, data)
    yield put(deleteClientCallSuccess(response?.data))
    yield put(getAllClientCalls({ offset: 0 }))
    toggle()
    toast.success("Deleted client call successfully")
  } catch (e) {
    let err = e?.response?.data?.non_field_errors
      ? e.response.data.non_field_errors
      : e?.response?.data?.detail
      ? e.response.data.detail
      : "Something went wrong."
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(deleteClientCallFailure("Connection Error"))
    } else {
      toast.error(err)
      yield put(deleteClientCallFailure(err))
    }
  }
}

async function filterClientCallsAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/client_call/filters/?${
    data.category ? `category=["${data.category}"]&` : ""
  }to_date=${data.dateTo}&from_date=${data.dateFrom}`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* filterClientCall({ data, toggle }) {
  try {
    const response = yield call(filterClientCallsAPI, data)
    yield put(filterClientCallSuccess(response?.data))
    toggle(false)
  } catch (e) {
    let err = e?.response?.data
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(filterClientCallFailure("Connection Error"))
    } else {
      toast.error("Failed to get data, please try again")
      yield put(filterClientCallFailure("Failed to get data, please try again"))
    }
  }
}

async function searchClientCallsAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/client_call/filters/?name=${data.name}`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* searchClientCall({ data, toggle }) {
  try {
    const response = yield call(searchClientCallsAPI, data)
    yield put(searchClientCallSuccess(response?.data))
  } catch (e) {
    let err = e?.response?.data?.non_field_errors
      ? e.response?.data?.non_field_errors
      : e?.response?.data?.detail
      ? e.response?.data?.detail
      : "Something went wrong."
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(searchClientCallFailure("Connection Error"))
    } else {
      toast.error(err)
      yield put(searchClientCallFailure(err))
    }
  }
}

async function getCategoriesAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/category/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getCtegories({ data }) {
  try {
    const response = yield call(getCategoriesAPI, data)
    yield put(getCategorySuccess(response?.data))
  } catch (e) {
    let err = e?.response?.data?.non_field_errors
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(getCategoryFailure("Connection Error"))
    } else {
      toast.error(err)
      yield put(getCategoryFailure(err))
    }
  }
}

export default all([
  takeLatest(GET_ALL_CLIENT_CALLS, getAllClientCall),
  takeLatest(UPDATE_CLIENT_CALL, updateClientCall),
  takeLatest(DELETE_CLIENT_CALL, deleteClientCall),
  takeLatest(CREATE_CLIENT_CALL, createClientCall),
  takeLatest(FILTER_CLIENT_CALL, filterClientCall),
  takeLatest(SEARCH_CLIENT_CALL, searchClientCall),
  takeLatest(GET_CATEGORY, getCtegories)
])
