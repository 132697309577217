import React from "react"
import { copyToClipboard, showSuccessToast } from "utils/functions"

import Like from "../../../Like"
import Dislike from "../../../Dislike"
import CopyConversation from "../../../CopyConversation"

import "./style.scss"
import { prepareMessageConversation } from "WebVisual/Components/Chat/hooks"

export const MessageActions = ({ isStreaming, message, onAddFeedback }) => {
  const handleCopy = () => {
    copyToClipboard(prepareMessageConversation(message))
    showSuccessToast("Chatbot response copied successfully")
  }

  const handleAddFeedback = ({ note, reaction }) => {
    onAddFeedback({ message_id: message.id, note, reaction })
  }

  if (isStreaming || message.error) {
    return null
  }

  return (
    <div className="assistant_message_actions">
      <Dislike
        id={message.id}
        feedbackText={""}
        reaction={message.reaction}
        onAddFeedback={handleAddFeedback}
      />
      <Like
        id={message.id}
        feedbackText={""}
        reaction={message.reaction}
        onAddFeedback={handleAddFeedback}
      />
      <CopyConversation onCopy={handleCopy} />
    </div>
  )
}

export default MessageActions
