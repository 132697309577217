import { all, call, put, takeLatest } from "redux-saga/effects"

import XHR from "../../../utils/XHR"

import { BASE_URL } from "config/app"
import { toast } from "react-hot-toast"
import { fetchEventSource } from "utils/http"
import { MESSAGE_TYPE } from "./constants"

const TYPE = "WEBVISUAL_CHAT_"

export const constants = {
  GET_CHAT: `${TYPE}GET_CHAT`,
  GET_CHAT_SUCCESS: `${TYPE}GET_CHAT_SUCCESS`,
  CREATE_CHAT: `${TYPE}CREATE_CHAT`,
  CREATE_CHAT_SUCCESS: `${TYPE}CREATE_CHAT_SUCCESS`,
  ADD_CHAT_MESSAGE_REACTION: `${TYPE}ADD_CHAT_MESSAGE_REACTION`,
  ADD_CHAT_MESSAGE_REACTION_SUCCESS: `${TYPE}ADD_CHAT_MESSAGE_REACTION_SUCCESS`,
  CHAT_ASK_QUESTION: `${TYPE}CHAT_ASK_QUESTION`,
  CHAT_ASK_QUESTION_SUCCESS: `${TYPE}CHAT_ASK_QUESTION_SUCCESS`,
  CHAT_ADD_USER_MESSAGE: `${TYPE}CHAT_ADD_USER_MESSAGE`,
  CHAT_CHUNK_ASSISTANT_MESSAGE: `${TYPE}CHAT_CHUNK_ASSISTANT_MESSAGE`,
  CHAT_UPDATE_LAST_MESSAGE: `${TYPE}CHAT_UPDATE_LAST_MESSAGE`,
  CHAT_SOURCES_ASSISTANT_MESSAGE: `${TYPE}CHAT_SOURCES_ASSISTANT_MESSAGE`,
  CHAT_STOP_ASSISTANT_MESSAGE_STREAMING: `${TYPE}CHAT_STOP_ASSISTANT_MESSAGE_STREAMING`,
  CLEAR_CHAT: `${TYPE}CLEAR_CHAT`
}

const initAssistantMessage = {
  type: MESSAGE_TYPE.ASSISTANT,
  message: "",
  sources: [],
  created_at: new Date(),
  reaction: null
}

const initUserMessage = {
  type: MESSAGE_TYPE.USER,
  message: "",
  created_at: new Date()
}

const initialState = {
  chat: null,
  requesting: false,
  isStreaming: false
}

export const VisualChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CHAT:
      return {
        ...state,
        requesting: true
      }
    case constants.GET_CHAT_SUCCESS: {
      return {
        ...state,
        chat: action.response,
        requesting: false
      }
    }
    case constants.CREATE_CHAT_SUCCESS: {
      return {
        ...state,
        chat: action.response
      }
    }
    case constants.CHAT_CHUNK_ASSISTANT_MESSAGE: {
      const messages = [...(state.chat.messages || [])]
      const message = messages[messages.length - 1]

      message.message += action.response

      return {
        ...state,
        chat: {
          ...state.chat,
          messages
        }
      }
    }
    case constants.CHAT_UPDATE_LAST_MESSAGE: {
      const messages = [...(state.chat.messages || [])]
      const message = messages[messages.length - 1]

      messages[messages.length - 1] = { ...message, ...action.response }

      return {
        ...state,
        chat: {
          ...state.chat,
          messages: [...messages]
        }
      }
    }
    case constants.CHAT_ASK_QUESTION: {
      const messages = [
        ...(state.chat.messages || []),
        {
          id: new Date().getTime(),
          ...initUserMessage
        }
      ]

      return {
        ...state,
        chat: {
          ...state.chat,
          messages: [...messages]
        }
      }
    }
    case constants.CHAT_ADD_USER_MESSAGE: {
      const messages = (state.chat.messages || []).slice(0, -1)

      const baseAssistantMessage = {
        ...initAssistantMessage,
        id: new Date().getTime(),
        created_at: new Date(),
        sources: []
      }

      return {
        ...state,
        isStreaming: true,
        chat: {
          ...state.chat,
          messages: [...messages, action.response, baseAssistantMessage]
        }
      }
    }
    case constants.ADD_CHAT_MESSAGE_REACTION_SUCCESS: {
      const reaction = action.response
      const messages = state.chat.messages

      const updatedMessages = messages.map(message => {
        if (message.id === reaction.message) {
          message.reaction = reaction
        }

        return message
      })

      return {
        ...state,
        chat: {
          ...state.chat,
          messages: updatedMessages
        }
      }
    }
    case constants.CHAT_STOP_ASSISTANT_MESSAGE_STREAMING: {
      return {
        ...state,
        isStreaming: false
      }
    }
    case constants.CLEAR_CHAT: {
      return {
        ...initialState
      }
    }

    default: {
      return state
    }
  }
}

async function getChatByIdAPI(id) {
  const URL = `${BASE_URL}/api/v1/chat/${id}/`
  const prc_authToken = localStorage.getItem("prc_authToken")

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getChatById({ id, onError }) {
  try {
    const response = yield call(getChatByIdAPI, id)

    yield put({
      type: constants.GET_CHAT_SUCCESS,
      response: response?.data
    })
  } catch (e) {
    toast.error("Chat not found")
    onError()
  }
}

async function createChatAPI() {
  const URL = `${BASE_URL}/api/v1/chat/`
  const prc_authToken = localStorage.getItem("prc_authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "POST",
    data: {}
  }
  return XHR(URL, options)
}

function* createChat({ cbSuccess }) {
  try {
    const response = yield call(createChatAPI)

    yield put({
      type: constants.CREATE_CHAT_SUCCESS,
      response: response?.data
    })

    cbSuccess(response?.data)
  } catch (e) {
    toast.error("Chat not created")
  }
}

async function addChatMessageReactionAPI(data) {
  const URL = `${BASE_URL}/api/v1/chat_message_reaction/`
  const prc_authToken = localStorage.getItem("prc_authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "POST",
    data: data
  }
  return XHR(URL, options)
}

function* addChatMessageReaction({ data }) {
  try {
    const response = yield call(addChatMessageReactionAPI, data)

    yield put({
      type: constants.ADD_CHAT_MESSAGE_REACTION_SUCCESS,
      response: response?.data
    })
  } catch (e) {
    toast.error("Chat message reaction not added")
  }
}

async function askChatQuestionEventSource({
  data,
  signal,
  onMessage,
  onError,
  onClose
}) {
  const URL = `${BASE_URL}/api/v1/chat_message/`
  const prc_authToken = localStorage.getItem("prc_authToken")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "POST",
    body: JSON.stringify(data),
    onmessage: onMessage,
    signal,
    retry: () => null,
    onerror: e => onError?.(e),
    openWhenHidden: true,
    onclose: onClose
  }

  return fetchEventSource(URL, options)
}

function* askChatQuestion({ data, signal, onMessage, onClose, onError }) {
  try {
    yield call(askChatQuestionEventSource, {
      data,
      signal,
      onMessage,
      onClose,
      onError
    })
  } catch (e) {
    toast.error("Chat question not asked")
  }
}

export default all([
  takeLatest(constants.GET_CHAT, getChatById),
  takeLatest(constants.CREATE_CHAT, createChat),
  takeLatest(constants.ADD_CHAT_MESSAGE_REACTION, addChatMessageReaction),
  takeLatest(constants.CHAT_ASK_QUESTION, askChatQuestion)
])
