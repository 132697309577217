import React, { useState } from "react"

import PageWrapper from "WebVisual/PageWrapper"
import PodcastList from "WebVisual/Components/PodcastList"
import ChatWidget from "WebVisual/Components/ChatWidget"

const Podcast = () => {
  const [search, setSearch] = useState("")

  return (
    <PageWrapper
      onChange={setSearch}
      title="Podcast"
      pageBodyHeader={<ChatWidget />}
    >
      <PodcastList search={search} />
    </PageWrapper>
  )
}

export default Podcast
