import React, { useState } from "react"

import PageWrapper from "WebVisual/PageWrapper"
import FavouriteArticles from "WebVisual/Components/FavouriteArticles"
import ChatWidget from "WebVisual/Components/ChatWidget"

const Favourite = () => {
  const [search, setSearch] = useState("")

  return (
    <PageWrapper
      onChange={setSearch}
      title="Favourite"
      pageBodyHeader={<ChatWidget />}
    >
      <FavouriteArticles search={search} />
    </PageWrapper>
  )
}

export default Favourite
