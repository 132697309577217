import { useSelector } from "react-redux"
import {
  copyToClipboard,
  showSuccessToast,
  getArticleUrlBySourceUrl
} from "utils/functions"
import { USER_TYPE_MATRIX } from "../constants"

export const useCopyChatConversation = () => {
  const { chat } = useSelector(state => state.VisualChatReducer)

  const handleCopyConversation = () => {
    copyToClipboard(prepareConversation(chat))
    showSuccessToast("Conversation copied successfully")
  }

  return {
    handleCopyConversation
  }
}

export const prepareMessageConversation = message => {
  let r = `${USER_TYPE_MATRIX[message.type]}: ${message.message}\n\n`

  if (message.sources.length) {
    r += `Sources:\n`
    message.sources.forEach(source => {
      r += `${getArticleUrlBySourceUrl(source.url)}\n`
    })

    r += "\n"
  }

  return r
}

const prepareConversation = chat => {
  const messages = (chat?.messages || []).reduce((r, item) => {
    r += prepareMessageConversation(item)

    return r
  }, "")

  return messages
}
