import React, { useState } from "react"
import { UncontrolledPopover, PopoverBody, Button } from "reactstrap"

import closeIcon from "../../../../../../../assets/img/closeIcon.png"

import "./style.scss"

const MIN_FEEDBACK_LENGTH = 1
const MAX_FEEDBACK_LENGTH = 500

const checkFeedbackLength = text => {
  const textTrim = text.trim()

  return (
    textTrim.length >= MIN_FEEDBACK_LENGTH &&
    textTrim.length <= MAX_FEEDBACK_LENGTH
  )
}

export const Feedback = ({
  feedbackText,
  popoverTarget,
  placeholder,
  isOpen,
  onToggle,
  onSubmit
}) => {
  const [text, setText] = useState(feedbackText || "")

  const handleToggle = () => {
    setText("")
    onToggle()
  }

  return (
    <UncontrolledPopover
      className="feedback-popover"
      placement="top"
      trigger="legacy"
      isOpen={isOpen}
      target={popoverTarget}
      toggle={handleToggle}
    >
      <PopoverBody>
        <div className="feedback">
          <div className="feedback__header">
            <div className="feedback__title">Feedback</div>
            <img
              className="feedback__close"
              onClick={onToggle}
              src={closeIcon}
              alt="close"
            />
          </div>

          <textarea
            className="feedback__input"
            value={text}
            onChange={e => setText(e.target.value)}
            placeholder={placeholder}
          />
          <div className="feedback__footer">
            <Button
              className="text-capitalize feedback__btn"
              color="red"
              disabled={!checkFeedbackLength(text)}
              onClick={() => onSubmit(text.trim())}
            >
              Submit
            </Button>
          </div>
        </div>
      </PopoverBody>
    </UncontrolledPopover>
  )
}

export default Feedback
