import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { constants } from "./../redux"

export const useGetChatInfo = id => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { chat, isStreaming } = useSelector(state => state.VisualChatReducer)

  const messages = chat?.messages || []

  useEffect(() => {
    if (id) {
      dispatch({
        type: constants.GET_CHAT,
        id,
        onError: () => {
          history.push("/user/home")
        }
      })
    }
  }, [id, dispatch])

  useEffect(() => {
    return () => {
      dispatch({
        type: constants.CLEAR_CHAT
      })
    }
  }, [dispatch])

  return {
    chat,
    messages,
    isStreaming
  }
}
