import React, { useState } from "react"
import "../style.scss"
import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { constants } from "../redux"

const FeedBack = () => {
  // const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const dispatch = useDispatch()
  const state = useSelector(state => state.VisualSettingsReducer)
  const userEmail = localStorage.getItem("userEmail")
  const postFeedback = () => {
    const prc_authToken = localStorage.getItem("userId")

    const data = {
      email: userEmail,
      message,
      user: prc_authToken
    }

    dispatch({
      type: constants.POST_FEEDBACK,
      data,
      // setEmail,
      setMessage
    })
  }

  return (
    <>
      <div className="feedback">
        <div className=" content-box">
          <div className="header d-flex justify-content-between">
            <h2>Feedback</h2>
          </div>
          <div className="form">
            <Form>
              <Row>
                <Col sm="5">
                  <FormGroup style={{ marginTop: 15 }}>
                    <Label>EMAIL</Label>
                    <Input
                      placeholder="Type email.."
                      type="email"
                      // onChange={e => {
                      //   setEmail(e.target.value)
                      // }}
                      disabled
                      value={userEmail}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup
                    style={{ marginTop: 15 }}
                    className="d-flex flex-column"
                  >
                    <Label>MESSAGE</Label>
                    <textarea
                      placeholder="Type here.."
                      type="text"
                      onChange={e => {
                        setMessage(e.target.value)
                      }}
                      value={message}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        <div className="buttons d-flex justify-content-end">
          <Button
            // onClick="#"
            className="savebtn"
            onClick={() => postFeedback()}
            disabled={message ? false : true}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  )
}

export default FeedBack
