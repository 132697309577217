import React, { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { ChatFooter, ChatHeader, ChatMessages } from "./components"
import { constants } from "./redux"
import {
  useCopyChatConversation,
  useGetChatInfo,
  useHistoryState,
  useChatAutoScroll,
  useSendChatMessage,
  useHandleChatGoBack
} from "./hooks"

import "./style.scss"

const Chat = () => {
  const { id } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()

  const { messages, chat, isStreaming } = useGetChatInfo(id)
  const { handleCopyConversation } = useCopyChatConversation()
  const { handleClearState, message } = useHistoryState()
  const { chatMessagesContainerRef } = useChatAutoScroll(messages, isStreaming)
  const { handleSendMessage } = useSendChatMessage(chat)
  const { handleBackClick } = useHandleChatGoBack(handleClearState)

  const handleStartNewChat = () => {
    dispatch({
      type: constants.CREATE_CHAT,
      cbSuccess: chat => {
        history.push({
          pathname: `/user/chat/${chat.id}`,
          state: { isFromChat: true }
        })
      }
    })
  }

  const handleAddFeedback = ({ message_id, note, reaction }) => {
    dispatch({
      type: constants.ADD_CHAT_MESSAGE_REACTION,
      data: { message_id, note, reaction }
    })
  }

  useEffect(() => {
    if (message && chat) {
      handleSendMessage(message)
      handleClearState()
    }
  }, [message, chat, handleClearState, handleSendMessage])

  return (
    <div className="chat-wrapper">
      <ChatHeader
        onBackClick={handleBackClick}
        onCopyConversation={handleCopyConversation}
        onStartNewChat={handleStartNewChat}
        isDisabledAddNewChatButton={!messages.length || isStreaming}
        isDisabledCopyConversationButton={!messages.length || isStreaming}
      />
      <div className="chat-content">
        <ChatMessages
          messages={messages}
          onAddFeedback={handleAddFeedback}
          isStreaming={isStreaming}
          chatMessagesContainerRef={chatMessagesContainerRef}
        />
      </div>
      <div className="chat-footer">
        <ChatFooter
          onSubmit={handleSendMessage}
          isDisabledSubmitButton={isStreaming}
        />
      </div>
    </div>
  )
}

export default Chat
