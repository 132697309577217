import React, { useState } from "react"

import copyConversationIcon from "../../../../../assets/img/copyConversationIcon.png"
import checkIcon from "../../../../../assets/img/checkIcon.png"

import "./style.scss"

const COPY_TIMEOUT_DURATION = 3000

const CopyConversationButton = ({
  onCopyConversation,
  onChangeCopyState,
  isDisabled,
  isMobile
}) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleChangeCopyState = state => {
    if (onChangeCopyState) {
      onChangeCopyState(state)
    }
  }

  const handleCopyConversation = () => {
    if (isDisabled) {
      return
    }

    if (!isCopied) {
      setIsCopied(true)
      handleChangeCopyState(true)

      setTimeout(() => {
        setIsCopied(false)
        handleChangeCopyState(false)
      }, COPY_TIMEOUT_DURATION)

      onCopyConversation()
    }
  }

  return (
    <div
      onClick={handleCopyConversation}
      className={`copy-conversation-button ${isMobile ? "mobile" : ""} ${
        isCopied ? "copied" : ""
      } ${isDisabled ? "disabled" : ""}`}
    >
      <img
        width="20px"
        src={isCopied ? checkIcon : copyConversationIcon}
        alt="copy conversation"
      />
      <span>{isCopied ? "COPIED CONVERSATION" : "COPY CONVERSATION"}</span>
    </div>
  )
}

export default CopyConversationButton
