import React, { useState, useEffect } from "react"
import Images from "utils/Images"
import Pagination from "components/Pagination/Pagination"
import ActivityIndicator from "components/ActivityIndicator"
import DateTimePicker from "react-datetime-picker"
import "react-calendar/dist/Calendar.css"
import moment from "moment"
import "../Notification/style.css"

//Components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  Spinner
} from "reactstrap"
import Select from "react-select"

import useForm from "utils/useForm"
import {
  adminNotificationRequest,
  adminNotificationFaluire,
  getNotificationRequest,
  deleteNotificationRequest,
  updateNotificationRequest,
  updateNotificationFaluire
} from "./redux"
import { connect } from "react-redux"
import CButton from "components/Button"

const Notification = props => {
  const { adminNotification, requesting, updateRequesting, createRequesting } =
    props
  const [creatNotification, setCreatNotification] = useState(false)
  const [offset, setOffset] = useState(0)
  const [editNotification, setEditNotification] = useState(false)
  const [selectedTime, setSelectedTime] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [user, setUser] = useState(false)
  const [deletedModal, setDeletedModal] = useState(false)
  const [dropdownValue, setDropDownValue] = useState({
    label: "Subscribed",
    value: true
  })
  const [selectPageValue, setSelectPageValue] = useState(1)

  const [value, onChange] = useState(new Date())

  const deleteModal = () => setDeletedModal(!deletedModal)
  const creatNotificationModal = () => {
    if (createRequesting) {
      props.adminNotificationFaluire(false)
    }
    setCreatNotification(!creatNotification)
    setState(stateSchema)
    setSelectedTime(false)
  }
  const editNotificationModal = () => {
    if (updateRequesting) {
      props.updateNotificationFaluire(false)
    }
    setIsEdit(!isEdit)
    setEditNotification(!editNotification)
  }

  const stateSchema = {
    notificationName: {
      value: "",
      error: ""
    },
    subscribed: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    notificationName: {
      required: true
    },
    subscribed: {
      required: true
    }
  }

  const { state, setState, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const onUpdateSuccessHandler = () => {
    setEditNotification(!editNotification)
    setState(stateSchema)
  }

  const postNotification = () => {
    const userId = localStorage.getItem("userId")
    const data = {
      text: state.notificationName.value,
      activated_task_time: value,

      // time: value,
      for_subscribed_user: dropdownValue.value,
      admin: Number(userId)
    }

    props.adminNotificationRequest(data, creatNotificationModal)
  }

  const updateNotification = () => {
    const userId = localStorage.getItem("userId")
    const data = {
      id: user.id,
      form: {
        text: state.notificationName.value,
        activated_task_time: value,
        for_subscribed_user: dropdownValue.value,
        admin: Number(userId)
      }
    }

    props.updateNotificationRequest(data, onUpdateSuccessHandler)
  }

  const options = [
    {
      label: "Subscribed",
      value: true
    },
    {
      label: "Trial Period",
      value: false
    }
  ]

  function handleDropChange(option) {
    setDropDownValue(option)
    handleOnChange("subscribed", option)
  }

  const onSelectTime = item => {
    if (item == selectedTime) {
      setSelectedTime(false)
    } else {
      setSelectedTime(item)
    }
  }

  useEffect(() => {
    props.getNotificationRequest({ offset: 0 })
  }, [])

  useEffect(() => {
    props.getNotificationRequest({ offset: offset })
  }, [offset])

  useEffect(() => {
    setDropDownValue({ ...dropdownValue, value: user.for_subscribed_user })
    setSelectedTime(user.activated_task_time)
    handleOnChange("notificationName", user.text)
  }, [isEdit])

  return (
    <div className="ml-lg-5 mr-lg-5 mr-3 ml-3">
      <Row>
        <Col md="12">
          <Card className="card-plain">
            <CardHeader style={{ marginBottom: 20 }}>
              <Row
                className="d-md-flex m-0"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <CardTitle
                  tag="h4"
                  style={{
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "22px",
                    lineHeight: "27px",
                    marginTop: "24px",
                    color: "#4A5981"
                  }}
                >
                  Notifications
                </CardTitle>
                <div>
                  {/* <Button
                    style={{
                      backgroundColor: "#F01716",
                      color: "#FFFF",
                      border: "1px solid #F01716",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                      height: 46,
                      whiteSpace: "nowrap"
                    }}
                    onClick={creatNotificationModal}
                  >
                    + Create New Notification
                  </Button> */}
                  <CButton
                    onClick={creatNotificationModal}
                    btnStyle={{
                      backgroundColor: "#F01716",
                      color: "#FFFF",
                      border: "1px solid #F01716",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                      height: 46,
                      whiteSpace: "nowrap",
                      border: "none",
                      fontWeight: 600,
                      fontSize: "14px",
                      padding: "14px 32px 14px"
                    }}
                    className="btnStyle"
                    title="+ Create New Notification"
                  />
                </div>
              </Row>
            </CardHeader>
            {adminNotification && adminNotification.results.length > 0 ? (
              adminNotification.results.map(item => {
                return (
                  <CardBody style={{ padding: 0 }}>
                    <Row style={{}}>
                      <Col
                        className="px-4 py-3 m-4"
                        style={{
                          backgroundColor: "white",

                          borderRadius: "8px",
                          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                          border: "1px solid #EAEAEA"
                        }}
                      >
                        <div className="">
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              lineHeight: "21px",
                              marginBottom: "10px",
                              color: "#00000"
                            }}
                          >
                            {item.text}
                          </p>

                          <div
                            style={{
                              alignItems: "center",
                              flexDirection: "row",
                              display: "flex",
                              marginBottom: "10px"
                            }}
                          >
                            <img
                              alt=""
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "4px"
                              }}
                              src={Images.time}
                            />
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "21px",
                                color: "#939393",
                                marginBottom: 0,
                                marginLeft: 8
                              }}
                            >
                              Sent on{" "}
                              {moment(item.activated_task_time).format(
                                "YYYY-MM-DD, h:mm:ss A"
                              )}
                            </p>
                          </div>
                          <div style={{ display: "flex" }}>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "21px",
                                color: "#939393",
                                marginBottom: 0,
                                marginRight: 8
                              }}
                            >
                              Sent to
                            </p>
                            <p
                              style={{
                                fontWeight: "600",
                                fontSize: "12px",
                                lineHeight: "21px",
                                color: "#00000",
                                marginBottom: 0
                              }}
                            >
                              {item.for_subscribed_user
                                ? "Subscriber"
                                : "Trial Period"}
                            </p>
                          </div>
                          <hr />
                          <div
                            style={{
                              display: "flex"
                            }}
                          >
                            <Button
                              onClick={() => {
                                editNotificationModal()
                                setUser(item)
                              }}
                              className=" text-capitalize"
                              style={{
                                backgroundColor: "#fff",
                                height: "36px",
                                color: "#F01716",
                                fontSize: "12px",
                                fontWeight: "700px",
                                border: "1px solid #F01716",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "6px",
                                boxShadow:
                                  "2px 4px 12px rgba(rgba(0, 0, 0, 0.25)",
                                margin: "0px"
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: "10px",
                                  width: "10px",

                                  marginRight: "4px"
                                }}
                                src={Images.edit}
                              />
                              Edit
                            </Button>
                            <Button
                              className="btn-link text-capitalize"
                              onClick={() => {
                                deleteModal()
                                setUser(item)
                              }}
                              style={{
                                color: "#F01716",
                                display: "flex",
                                alignItems: "center",
                                margin: "0px",
                                marginLeft: 5
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  height: 12,
                                  width: 10,
                                  marginRight: "5px",
                                  marginTop: "-2px",
                                  borderRadius: "0px"
                                }}
                                src={Images.delete}
                              />
                              Delete
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                )
              })
            ) : (
              <ActivityIndicator
                requesting={requesting}
                data={false}
                message={"No notificaton found."}
              />
            )}

            {adminNotification && adminNotification.count > 10 ? (
              <Row style={{ alignItems: "center", justifyContent: "center" }}>
                <Pagination
                  totalCount={adminNotification && adminNotification.count}
                  offset={offset}
                  setOffset={setOffset}
                  selectPageValue={selectPageValue}
                  setSelectPageValue={setSelectPageValue}
                />
              </Row>
            ) : null}
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={creatNotification}
        toggle={creatNotificationModal}
        style={{ maxWidth: "762px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <label
            className="p-0 m-0"
            style={{ fontSize: "16px", fontWeight: "700", color: "##000000" }}
          >
            Create New Notification
          </label>
          <img
            alt=""
            onClick={creatNotificationModal}
            style={{
              height: 15,
              width: 15,

              marginLeft: "20px"
            }}
            src={Images.cross}
          />
        </div>

        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Row className="mt-md-4" style={{ justifyContent: "center" }}>
            <Col sm="12">
              <Row style={{ justifyContent: "center" }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "rgba(0, 0, 0, 1)",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}
                    sm="6"
                  >
                    NOTIFICATION NAME
                  </Label>
                  <FormGroup>
                    <Input
                      placeholder="Notification message"
                      type="textarea"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: 114,
                        color: "black"
                      }}
                      onChange={e =>
                        handleOnChange("notificationName", e.target.value)
                      }
                    />
                  </FormGroup>
                  {state.notificationName.error && (
                    <label
                      style={{
                        color: "red",
                        display: "flex",
                        textAlign: "left",
                        marginTop: -6
                      }}
                    >
                      {state.notificationName.error}
                    </label>
                  )}
                </Col>
              </Row>
              <div>
                <Label
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    padding: "0px",
                    marginBottom: "7px",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginTop: 20
                  }}
                >
                  SCHEDULE TIME
                </Label>
              </div>
              <div style={{ width: "100%" }}>
                <DateTimePicker
                  className={"datePickerStyle"}
                  onChange={onChange}
                  value={value}
                  amPmAriaLabel="Select AM/PM"
                  format="y-MM-dd hh:mm:ss a"
                />
              </div>
              {/* <Input
                  placeholder="Notification message"
                  type="date"
                  style={{
                    backgroundColor: "#F2F2F2"
                  }}
                  // onChange={e =>
                  //   handleOnChange("notificationName", e.target.value)
                  // }
                /> */}

              {/* <Row style={{ display: "flex", marginTop: 10, marginBottom: 10 }}>
                {Time.map((item, i) => {
                  return (
                    <FormGroup check>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: 20
                        }}
                      >
                        <Label check>
                          <Input
                            checked={item == selectedTime}
                            type="checkbox"
                            onChange={() => onSelectTime(item)}
                          />
                          <span
                            className="form-check-sign"
                            style={{
                              height: "10px",
                              backgroundColor: "#3A0F7D"
                            }}
                          />
                          <span
                            style={{
                              color: "#000000",
                              fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "17px"
                            }}
                          >
                            Everyday at {item}:00 AM
                          </span>
                        </Label>
                      </div>
                    </FormGroup>
                  )
                })}
              </Row> */}
              <div>
                <Label
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    padding: "0px",
                    marginBottom: "7px",
                    fontWeight: "600",
                    fontSize: "12px"
                  }}
                >
                  TO WHOM IT SENT
                </Label>
                <Select
                  placeholder="Select"
                  name="singleSelect"
                  isSearchable={false}
                  id="exampleSelect"
                  className="slectStyle"
                  style={{
                    height: "51px !important",
                    minHeight: "51px !important",
                    marginBottom: 33,
                    backgroundColor: "#F2F2F2",
                    color: "black"
                  }}
                  onChange={handleDropChange}
                  options={options}
                />
                {state.subscribed.error && (
                  <label
                    style={{
                      color: "red",
                      display: "flex",
                      textAlign: "left",
                      marginTop: -6
                    }}
                  >
                    {state.subscribed.error}
                  </label>
                )}
              </div>
              <hr />
              <Row style={{ justifyContent: "center", marginBottom: 20 }}>
                <Button
                  className="mr-lg-3"
                  style={{
                    backgroundColor: "#fff",
                    color: "#F01716",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50
                  }}
                  onClick={creatNotificationModal}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#F01716",
                    color: "#FFFF",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50
                  }}
                  onClick={postNotification}
                  disabled={disable}
                >
                  {createRequesting ? <Spinner size="sm" /> : "Create"}
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        isOpen={editNotification}
        toggle={editNotificationModal}
        style={{ maxWidth: "762px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            margin: "20px"
          }}
        >
          <label
            className="p-0 m-0"
            style={{ fontSize: "16px", fontWeight: "700", color: "##000000" }}
          >
            Edit Notification
          </label>
          <img
            alt=""
            onClick={editNotificationModal}
            style={{
              height: 15,
              width: 15,
              marginLeft: "20px"
            }}
            src={Images.cross}
          />
        </div>

        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Row className="mt-md-4" style={{ justifyContent: "center" }}>
            <Col sm="12">
              <Row style={{ justifyContent: "center" }}>
                <Col md="12">
                  <Label
                    style={{
                      color: "rgba(0, 0, 0, 1)",
                      padding: "0px",
                      marginBottom: "7px",
                      fontWeight: "600",
                      fontSize: "12px"
                    }}
                    sm="6"
                  >
                    NOTIFICATION NAME
                  </Label>
                  <FormGroup>
                    <Input
                      placeholder="Notification message"
                      type="textarea"
                      style={{
                        backgroundColor: "#F2F2F2",
                        height: 114,
                        color: "black"
                      }}
                      defaultValue={user?.text}
                      onChange={e =>
                        handleOnChange("notificationName", e.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div>
                <Label
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    padding: "0px",
                    marginBottom: "7px",
                    fontWeight: "600",
                    fontSize: "12px",
                    marginTop: 20
                  }}
                >
                  SCHEDULE TIME
                </Label>
              </div>
              <DateTimePicker
                className={"datePickerStyle"}
                onChange={onChange}
                value={value}
                amPmAriaLabel="Select AM/PM"
                format="y-MM-dd hh:mm:ss a"
              />
              {/* <Row style={{ display: "flex", marginTop: 10, marginBottom: 10 }}>
                {Time.map((item, i) => {
                  return (
                    <FormGroup check>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: 20
                        }}
                      >
                        <Label check>
                          <Input
                            checked={item == selectedTime}
                            type="checkbox"
                            onChange={() => onSelectTime(item)}
                          />
                          <span
                            className="form-check-sign"
                            style={{
                              height: "10px",
                              backgroundColor: "#3A0F7D"
                            }}
                          />
                          <span
                            style={{
                              color: "#000000",
                              fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "17px"
                            }}
                          >
                            Everyday at {item}:00 AM
                          </span>
                        </Label>
                      </div>
                    </FormGroup>
                  )
                })}
              </Row> */}
              <div>
                <Label
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    padding: "0px",
                    marginBottom: "7px",
                    fontWeight: "600",
                    fontSize: "12px"
                  }}
                >
                  TO WHOM IT SENT
                </Label>
                <Select
                  placeholder="Select"
                  name="singleSelect"
                  isSearchable={false}
                  id="exampleSelect"
                  className="slectStyle"
                  defaultValue={{
                    value: user.for_subscribed_user
                      ? "Subscribed"
                      : "Trial Period",
                    label: user.for_subscribed_user
                      ? "Subscribed"
                      : "Trial Period"
                  }}
                  style={{
                    height: "51 !important",
                    marginBottom: 33,
                    backgroundColor: "#F2F2F2",
                    color: "black"
                  }}
                  onChange={handleDropChange}
                  options={options}
                />
              </div>
              <hr />
              <Row style={{ justifyContent: "center", marginBottom: 20 }}>
                <Button
                  className="mr-lg-3"
                  onClick={editNotificationModal}
                  style={{
                    backgroundColor: "#fff",
                    color: "#F01716",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#F01716",
                    color: "#FFFF",
                    border: "1px solid #F01716",
                    borderRadius: "8px",
                    width: "151px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
                    height: 50
                  }}
                  onClick={updateNotification}
                >
                  {updateRequesting ? <Spinner size="sm" /> : "Save"}
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal isOpen={deletedModal} toggle={deleteModal}>
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <img
            alt=""
            style={{
              height: 45,
              width: 38,
              marginTop: "40px"
            }}
            src={Images.deleteDark}
          />
        </div>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
            fontSize: "22px",
            // fontFamily: "Libre Caslon Text",
            color: "#4A5981"
          }}
        >
          Delete Notification?
        </p>
        <p
          style={{
            marginTop: "-25px",
            padding: "35px",
            textAlign: "center",
            fontSize: "15px",
            // fontFamily: "Libre Caslon Text",
            color: "#000000"
          }}
        >
          Are you sure, you want to delete this notification? All the data
          related to this notification will get lost if you delete it.
        </p>

        <Row style={{ justifyContent: "center", marginBottom: "25px" }}>
          <Button
            className="mr-lg-3"
            onClick={() => deleteModal()}
            style={{
              backgroundColor: "#fff",
              color: "#F01716",
              border: "1px solid #F01716",
              borderRadius: "8px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              props.deleteNotificationRequest(user.id, setDeletedModal)
            }}
            style={{
              backgroundColor: "#F01716",
              color: "#FFFF",
              border: "1px solid #F01716",
              borderRadius: "8px",
              width: "151px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.25)",
              height: 50
            }}
          >
            {props.requesting ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Yes, Delete"
            )}
          </Button>
        </Row>
      </Modal>
    </div>
  )
}
const mapStateToProps = state => ({
  adminNotification: state.NotificationReducer.adminNotification,
  requesting: state.NotificationReducer.requesting,
  updateRequesting: state.NotificationReducer.updateRequesting,
  createRequesting: state.NotificationReducer.createRequesting
})

const mapDispatchToProps = dispatch => ({
  getNotificationRequest: data => dispatch(getNotificationRequest(data)),
  deleteNotificationRequest: (data, toggle) =>
    dispatch(deleteNotificationRequest(data, toggle)),
  updateNotificationRequest: (data, toggle) =>
    dispatch(updateNotificationRequest(data, toggle)),
  adminNotificationRequest: (data, toggle) =>
    dispatch(adminNotificationRequest(data, toggle)),
  adminNotificationFaluire: data => dispatch(adminNotificationFaluire(data)),
  updateNotificationFaluire: data => dispatch(updateNotificationFaluire(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Notification)
