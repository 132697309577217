import React, { useState } from "react"

import PageWrapper from "WebVisual/PageWrapper"
import ArticleCard from "WebVisual/Components/ArticleCard"
import ChatWidget from "WebVisual/Components/ChatWidget"

const Home = () => {
  const [search, setSearch] = useState("")

  return (
    <PageWrapper
      onChange={setSearch}
      title="Home"
      pageBodyHeader={<ChatWidget />}
    >
      <ArticleCard search={search} />
    </PageWrapper>
  )
}

export default Home
