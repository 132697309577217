import React from "react"

import { date_format } from "variables/date"
import { formatDate } from "utils/functions"

import "./style.scss"

export const MessageHeader = ({ message }) => {
  return (
    <div className="assistant_message_header">
      <span className="message_user_name">PRC Macro</span>
      <span className="message_time">
        {formatDate(message.created_at, date_format.time)}
      </span>
    </div>
  )
}

export default MessageHeader
