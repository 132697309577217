import React, { useState } from "react"
import {
  getFileName,
  openInNewTab,
  getArticleUrlBySourceUrl
} from "utils/functions"
import { Tooltip } from "reactstrap"

import chatPdfIcon from "../../../../../../../assets/img/chatPdfIcon.png"
import goBackArrowIcon from "../../../../../../../assets/img/goBackArrowIcon.png"

import "./style.scss"

export const SourcesList = ({ sources }) => {
  if (!sources?.length) {
    return null
  }

  return (
    <div className="sources_wrapper">
      <div className="sources_title">SOURCES:</div>
      <div className="sources">
        {sources.map(s => (
          <SourceItem key={s.id} source={s} />
        ))}
      </div>
    </div>
  )
}

const SourceItem = ({ source }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const fileName = getFileName(source.url)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const handleOpenSource = () => {
    const url = getArticleUrlBySourceUrl(source.url)
    openInNewTab(url)
  }

  return (
    <div
      className="source_item"
      id={"Tooltip-" + source.id}
      onClick={handleOpenSource}
    >
      <div className="source_item_left">
        <img className="source_icon" src={chatPdfIcon} />
        <div className="source-title">{fileName}</div>
        <Tooltip
          target={"Tooltip-" + source.id}
          isOpen={tooltipOpen}
          toggle={toggle}
        >
          {fileName}
        </Tooltip>
      </div>
      <img className="arrow-icon" src={goBackArrowIcon} />
    </div>
  )
}

export default SourcesList
