export const REACTION_TYPE = {
  LIKE: "like",
  DISLIKE: "dislike"
}

export const MAX_MESSAGE_LENGTH = 1000
export const MIN_MESSAGE_LENGTH = 1

export const MESSAGE_TYPE = {
  USER: "user",
  ASSISTANT: "assistant"
}

export const USER_TYPE_MATRIX = {
  [MESSAGE_TYPE.USER]: "You",
  [MESSAGE_TYPE.ASSISTANT]: "PRC Macro"
}
