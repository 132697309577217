import { all, call, put, takeLatest } from "redux-saga/effects"

import { toast } from "react-hot-toast"

import XHR from "../../utils/XHR"

import { BASE_URL } from "config/app"

//Types
const GET_PODCAST_REQUEST = "GET_PODCAST_REQUEST"
const PODCAST_END_PROCESS = "PODCAST_END_PROCESS"

const DELETE_PODCAST_REQUEST = "DELETE_PODCAST_REQUEST"
const DELETE_PODCAST_PROCESS = "DELETE_PODCAST_PROCESS"
const GET_CATEGORY = "GET_CATEGORY"
const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE"

const FILTER_PODCAST = "FILTER_PODCAST"
const FILTER_PODCAST_SUCCESS = "FILTER_PODCAST_SUCCESS"
const FILTER_PODCAST_FAILURE = "FILTER_PODCAST_FAILURE"

const SEARCH_PODCAST = "SEARCH_PODCAST"
const SEARCH_PODCAST_SUCCESS = "SEARCH_PODCAST_SUCCESS"
const SEARCH_PODCAST_FAILURE = "SEARCH_PODCAST_FAILURE"

const UPDATE_PODCAST = "UPDATE_PODCAST"
const UPDATE_PODCAST_SUCCESS = "UPDATE_PODCAST_SUCCESS"
const UPDATE_PODCAST_FALUIRE = "UPDATE_PODCAST_FALUIRE"

const ADD_PODCAST = "ADD_PODCAST"
const ADD_PODCAST_SUCCESS = "ADD_PODCAST_SUCCESS"
const ADD_PODCAST_FALUIRE = "ADD_PODCAST_FALUIRE"

//Actions
export const getPodcast = data => ({
  type: GET_PODCAST_REQUEST,
  data
})

export const podcastEndProcess = data => ({
  type: PODCAST_END_PROCESS,
  data
})
export const deletePodcasts = (data, deleteModal) => ({
  type: DELETE_PODCAST_REQUEST,
  data,
  deleteModal
})
export const deletePodcastsProcess = data => ({
  type: DELETE_PODCAST_PROCESS,
  data
})

export const getCategory = data => ({
  type: GET_CATEGORY,
  data
})

export const getCategorySuccess = data => ({
  type: GET_CATEGORY_SUCCESS,
  data
})

export const getCategoryFailure = error => ({
  type: GET_CATEGORY_FAILURE,
  error
})

export const filterPodcasts = (data, setIsToggle) => ({
  type: FILTER_PODCAST,
  data,
  setIsToggle
})

export const filterPodcastsSuccess = (data, setIsToggle) => ({
  type: FILTER_PODCAST_SUCCESS,
  data,
  setIsToggle
})

export const filterPodcastsFailure = data => ({
  type: FILTER_PODCAST_FAILURE,
  data
})

export const searchPodcasts = (data, setIsToggle) => ({
  type: SEARCH_PODCAST,
  data,
  setIsToggle
})

export const searchPodcastsSuccess = (data, setIsToggle) => ({
  type: SEARCH_PODCAST_SUCCESS,
  data,
  setIsToggle
})

export const searchPodcastsFailure = data => ({
  type: SEARCH_PODCAST_FAILURE,
  data
})

export const updatePodcasts = (data, toggle) => ({
  type: UPDATE_PODCAST,
  data,
  toggle
})

export const updatePodcastsSuccess = data => ({
  type: UPDATE_PODCAST_SUCCESS,
  data
})

export const updatePodcastsFailure = error => ({
  type: UPDATE_PODCAST_FALUIRE,
  error
})

export const publishPodcasts = (data, toggle) => ({
  type: ADD_PODCAST,
  data,
  toggle
})

export const publishPodcastsSuccess = data => ({
  type: ADD_PODCAST_SUCCESS,
  data
})

export const publishPodcastsFailure = error => ({
  type: ADD_PODCAST_FALUIRE,
  error
})

const initialState = {
  requesting: false,
  podcasts: false,
  deletePodcast: false,
  toggleModal: false,
  categories: false,
  filterRequesting: false,
  updateRequesting: false,
  searchRequesting: false,
  error: false,
  addedPodcast: false,
  publishRequesting: false,
  filteredPodcast: false,
  searchedPodcast: false
}

//Reducer
export const getPodcastReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PODCAST_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case FILTER_PODCAST:
      return {
        ...state,
        filterRequesting: true
      }

    case FILTER_PODCAST_SUCCESS:
      return {
        ...state,
        filterRequesting: false,
        filteredPodcast: action.data
      }

    case FILTER_PODCAST_FAILURE:
      return {
        ...state,
        filterRequesting: false,
        filteredPodcast: false
      }

    case SEARCH_PODCAST:
      return {
        ...state,
        searchRequesting: true
      }

    case SEARCH_PODCAST_SUCCESS:
      return {
        ...state,
        searchRequesting: false,
        searchedPodcast: action.data
      }

    case SEARCH_PODCAST_FAILURE:
      return {
        ...state,
        searchRequesting: false,
        searchedPodcast: false
      }

    case UPDATE_PODCAST:
      return {
        ...state,
        updateRequesting: true
      }

    case UPDATE_PODCAST_SUCCESS:
      return {
        ...state,
        updateRequesting: false
      }

    case UPDATE_PODCAST_FALUIRE:
      return {
        ...state,
        updateRequesting: false,
        error: action.error
      }
    case PODCAST_END_PROCESS:
      return {
        ...state,
        requesting: false,
        podcasts: action.data,
        filterRequesting: false
      }
    case DELETE_PODCAST_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case DELETE_PODCAST_PROCESS:
      return {
        ...state,
        requesting: false,
        toggleModal: false,
        deletePodcast: action.data
      }
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryRequesting: false,
        categories: action.data
      }

    case ADD_PODCAST:
      return {
        ...state,
        publishRequesting: true
      }

    case ADD_PODCAST_SUCCESS:
      return {
        ...state,
        publishRequesting: false,
        addedPodcast: action.data
      }

    case ADD_PODCAST_FALUIRE:
      return {
        ...state,
        publishRequesting: false,
        error: action.error
      }

    default:
      return state
  }
}

//Saga
async function getPodCastAPI(data) {
  const URL = `${BASE_URL}/api/v1/podcast/?limit=10&offset=${data.offset}`

  const prc_authToken = await localStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getPodCastFunction({ data }) {
  try {
    const response = yield call(getPodCastAPI, data)

    yield put(podcastEndProcess(response.data))
  } catch (e) {
    yield put(podcastEndProcess(false))
  }
}

async function deletePodcastsAPI(data) {
  const URL = `${BASE_URL}/api/v1/admin_podcast/${data}/`
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* deletePodcastsFunction({ data, deleteModal }) {
  try {
    const response = yield call(deletePodcastsAPI, data)

    yield put(deletePodcastsProcess(response))
    yield put(getPodcast({ offset: 0 }))
    toast.success(`Delete Podcast Successfully`)
    deleteModal()
    yield put(podcastEndProcess(response.data))
  } catch (e) {
    yield put(deletePodcastsProcess(false))
  }
}

async function getCategoriesAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/category/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getCtegories({ data }) {
  try {
    const response = yield call(getCategoriesAPI, data)
    yield put(getCategorySuccess(response?.data))
  } catch (e) {
    let err = e?.response?.data?.non_field_errors
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(getCategoryFailure("Connection Error"))
    } else {
      toast.error(err)
      yield put(getCategoryFailure(err))
    }
  }
}

async function filterPodcastsAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/podcast/filters/?${
    data.category ? `category=["${data.category}"]&` : ""
  }to_date=${data.dateTo}&from_date=${data.dateFrom}`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* filterPodcastsData({ data, setIsToggle }) {
  try {
    const response = yield call(filterPodcastsAPI, data)
    yield put(filterPodcastsSuccess(response?.data))
    setIsToggle(false)
  } catch (e) {
    let err = e?.response?.data //?.non_field_errors
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(filterPodcastsFailure("Connection Error"))
    } else {
      toast.error("Failed to get data, please try again later")
      yield put(
        filterPodcastsFailure("Failed to get data, please try again later")
      )
    }
  }
}

async function searchPodcastsAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/podcast/filters/?name=${data.name}`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "application/json"
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* searchPodcastsData({ data, setIsToggle }) {
  try {
    const response = yield call(searchPodcastsAPI, data)
    yield put(searchPodcastsSuccess(response?.data))
  } catch (e) {
    let err = e?.response?.data //?.non_field_errors
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(searchPodcastsFailure("Connection Error"))
    } else {
      toast.error("Failed to get data, please try again later")
      yield put(
        searchPodcastsFailure("Failed to get data, please try again later")
      )
    }
  }
}

async function updatePodcastsAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/admin_podcast/${data.id}/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "multipart/form-data"
    },
    method: "PATCH",
    data: data.formdata
  }

  return XHR(URL, options)
}

function* updatePodcastsData({ data, toggle }) {
  try {
    const response = yield call(updatePodcastsAPI, data)
    yield put(updatePodcastsSuccess(response.data))
    yield put(getPodcast({ offset: 0 }))
    toggle()
    toast.success(`Podcast updated successfully`)
  } catch (e) {
    let err = e?.response?.data?.non_field_errors
      ? e.response.data.non_field_errors
      : e?.response?.data?.detail
      ? e.response.data.detail
      : "Something went wrong."
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(updatePodcastsFailure("Connection Error"))
    } else {
      toast.error(err)
      yield put(updatePodcastsFailure(err))
    }
  }
}

async function publishPodcastsAPI(data) {
  const prc_authToken = await localStorage.getItem("prc_authToken")
  const URL = `${BASE_URL}/api/v1/admin_podcast/`
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Token ${prc_authToken}`,
      "Content-Type": "multipart/form-data"
    },
    method: "POST",
    data: data.formdata
  }

  return XHR(URL, options)
}

function* publishPodcastsData({ data, toggle }) {
  try {
    const response = yield call(publishPodcastsAPI, data)
    yield put(publishPodcastsSuccess(response))
    yield put(getPodcast({ offset: 0 }))
    toggle()
    toast.success(`Podcast created successfully`)
  } catch (e) {
    if (e.message === "Network Error") {
      toast.error("Connection Error")
      yield put(publishPodcastsFailure("Connection Error"))
    } else {
      let err = e?.response?.data?.non_field_errors
        ? e.response.data.non_field_errors
        : e?.response?.data["detail"]
        ? e.response.data["detail"][0]
          ? e.response.data["detail"][0]
          : e.response.data["detail"]
        : e?.response?.data["category"]
        ? e.response.data["category"][0]
          ? e.response.data["category"][0]
          : e.response.data["category"]
        : e.response.data["logo"]
        ? "No logo was submitted."
        : "Something went wrong."
      toast.error(err)
      yield put(publishPodcastsFailure(err))
    }
  }
}

export default all([
  takeLatest(ADD_PODCAST, publishPodcastsData),
  takeLatest(GET_PODCAST_REQUEST, getPodCastFunction),
  takeLatest(DELETE_PODCAST_REQUEST, deletePodcastsFunction),
  takeLatest(GET_CATEGORY, getCtegories),
  takeLatest(UPDATE_PODCAST, updatePodcastsData),
  takeLatest(SEARCH_PODCAST, searchPodcastsData),
  takeLatest(FILTER_PODCAST, filterPodcastsData)
])
