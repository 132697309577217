import React, { useState } from "react"

import { REACTION_TYPE } from "WebVisual/Components/Chat/constants"
import chatDislikeIcon from "../../../../../../../assets/img/chatDislikeIcon.png"
import chatDislikeFilledIcon from "../../../../../../../assets/img/chatDislikeFilledIcon.png"

import Feedback from "../Feedback"

import "./style.scss"

export const Dislike = ({ onAddFeedback, feedbackText, reaction, id }) => {
  const [isOpen, setIsOpen] = useState(false)

  const isAdded = reaction?.reaction === REACTION_TYPE.DISLIKE

  const handleToggle = () => {
    if (!reaction) {
      setIsOpen(!isOpen)
    }
  }

  const handleSubmit = note => {
    onAddFeedback({ note, reaction: REACTION_TYPE.DISLIKE })
    setIsOpen(false)
  }

  if (reaction && !isAdded) {
    return null
  }

  return (
    <div className="dislike">
      <button
        className={`dislike-btn ${isOpen ? "opened" : ""}`}
        type="button"
        id={`dislikeFocus${id}`}
      >
        <img
          className="dislike-icon"
          src={isAdded ? chatDislikeFilledIcon : chatDislikeIcon}
          alt="Dislike"
        />
      </button>
      <Feedback
        popoverTarget={`dislikeFocus${id}`}
        placeholder="Tell us how we can improve..."
        isOpen={isOpen}
        onToggle={handleToggle}
        onSubmit={handleSubmit}
        feedbackText={feedbackText}
      />
    </div>
  )
}

export default Dislike
