import React, { useState } from "react"

import copyConversationIcon from "../../../../../../../assets/img/copyConversationIcon.png"
import checkIcon from "../../../../../../../assets/img/checkIcon.png"

import "./style.scss"

const COPY_TIMEOUT_DURATION = 3000

export const CopyConversation = ({ onCopy }) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = () => {
    if (!isCopied) {
      setIsCopied(true)
      onCopy()

      setTimeout(() => {
        setIsCopied(false)
      }, COPY_TIMEOUT_DURATION)
    }
  }

  return (
    <div className="copy-conversation">
      <img
        className="copy-conversation-icon"
        src={isCopied ? checkIcon : copyConversationIcon}
        alt="copy"
        onClick={handleCopy}
      />
    </div>
  )
}

export default CopyConversation
