import React from "react"
import ReactDOM from "react-dom"
import { Switch, Router, Redirect } from "react-router-dom"
import { store, history, persistor } from "./Redux/store"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import RouteGuard from "./RouterGuard"
import { Toaster } from "react-hot-toast"

import AuthLayout from "layouts/Auth.js"
import AdminLayout from "layouts/Admin.js"
import UserLayout from "layouts/User"

import "bootstrap/dist/css/bootstrap.css"
import "assets/scss/paper-dashboard.scss?v=1.2.0"
import "assets/demo/demo.css"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import App from "App"

const accessToken = localStorage.getItem("prc_authToken")

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster position="top-right" />

        <App />
        {/* <Router history={history}>
          <Switch>
            <RouteGuard
              path="/auth"
              component={props => <AuthLayout {...props} />}
            />
            <RouteGuard
              path="/admin"
              component={props => <AdminLayout {...props} />}
              isProtected
            />
            <RouteGuard
              path="/user"
              component={props => <UserLayout {...props} />}
              isProtected
            />
            {accessToken ? (
              <Redirect to="/user" />
            ) : (
              <Redirect to="/auth/login" />
            )}
          </Switch>
        </Router> */}
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)
