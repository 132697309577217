import { all, call, put, takeLatest } from "redux-saga/effects"

import XHR from "../../../utils/XHR"

import { BASE_URL } from "config/app"
import { toast } from "react-hot-toast"

const TYPE = "WEBVISUAL_CHAT_WIDGET_"

export const constants = {
  GET_LAST_CREATED_CHAT: `${TYPE}GET_LAST_CREATED_CHAT`,
  GET_LAST_CREATED_CHAT_SUCCESS: `${TYPE}GET_LAST_CREATED_CHAT_SUCCESS`
}

const initialState = {
  last_created_chat: null,
  requesting: false
}

export const VisualChatWidgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_LAST_CREATED_CHAT:
      return {
        ...state,
        requesting: true
      }
    case constants.GET_LAST_CREATED_CHAT_SUCCESS: {
      return {
        ...state,
        last_created_chat: action.response,
        requesting: false
      }
    }

    default: {
      return state
    }
  }
}

async function getLastCreatedChatAPI() {
  const URL = `${BASE_URL}/api/v1/chat/last/`
  const prc_authToken = localStorage.getItem("prc_authToken")

  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${prc_authToken}`
    },
    method: "GET"
  }

  return XHR(URL, options)
}

function* getLastCreatedChat() {
  try {
    const response = yield call(getLastCreatedChatAPI)

    yield put({
      type: constants.GET_LAST_CREATED_CHAT_SUCCESS,
      response: response?.data?.chat
    })
  } catch (e) {
    toast.error("Failed to get last created chat")
  }
}

export default all([
  takeLatest(constants.GET_LAST_CREATED_CHAT, getLastCreatedChat)
])
