import React, { useEffect } from "react"
import { Input, Button } from "reactstrap"
import sendIcon from "../../../../../assets/img/sendIcon.png"
import { MAX_MESSAGE_LENGTH, MIN_MESSAGE_LENGTH } from "../../constants"

import "./style.scss"

const isValidText = text => {
  const trimText = (text || "").trim()

  return (
    trimText.length >= MIN_MESSAGE_LENGTH &&
    trimText.length <= MAX_MESSAGE_LENGTH
  )
}

const ChatFooter = ({ onSubmit, initValue, isDisabledSubmitButton }) => {
  const [text, setText] = React.useState("")

  const handleSubmit = () => {
    onSubmit(text.trim())
    setText("")
  }

  const handleKeyDown = e => {
    if (e.key === "Enter" && !isDisabledSubmitButton) {
      handleSubmit()
    }
  }

  useEffect(() => {
    if (initValue) {
      setText(initValue)
    }
  }, [initValue])

  return (
    <div className="ChatFooterWrapper">
      <div className="ChatFooterInputWrapper">
        <Input
          className="ChatFooterInput"
          placeholder="Ask a question"
          type="text"
          value={text}
          onChange={e => setText(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button
          type="submit"
          className="ChatFooterSendButton"
          disabled={!isValidText(text) || isDisabledSubmitButton}
          onClick={handleSubmit}
        >
          <img className="" width="20px" src={sendIcon} alt="search" />
        </Button>
      </div>
      <div className="ChatFooterText">
        PRC Macro Chat uses AI and can make mistakes. Results are not the
        investment advice.
      </div>
    </div>
  )
}

export default ChatFooter
