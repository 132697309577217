import React, { useState } from "react"

import { REACTION_TYPE } from "WebVisual/Components/Chat/constants"
import chatLikeIcon from "../../../../../../../assets/img/chatLikeIcon.png"
import chatLikeFilledIcon from "../../../../../../../assets/img/chatLikeFilledIcon.png"

import Feedback from "../Feedback"

import "./style.scss"

export const Like = ({ onAddFeedback, feedbackText, reaction, id }) => {
  const [isOpen, setIsOpen] = useState(false)
  const isAdded = reaction?.reaction === REACTION_TYPE.LIKE

  const handleToggle = () => {
    if (!reaction) {
      setIsOpen(!isOpen)
    }
  }

  const handleSubmit = note => {
    onAddFeedback({ note, reaction: REACTION_TYPE.LIKE })
    setIsOpen(false)
  }

  if (reaction && !isAdded) {
    return null
  }

  return (
    <div className="like">
      <button
        className={`like-btn ${isOpen ? "opened" : ""}`}
        type="button"
        id={`likeFocus${id}`}
      >
        <img
          className="like-icon"
          src={isAdded ? chatLikeFilledIcon : chatLikeIcon}
          alt="like"
        />
      </button>
      <Feedback
        popoverTarget={`likeFocus${id}`}
        placeholder="Let us know what you liked..."
        isOpen={isOpen}
        onToggle={handleToggle}
        onSubmit={handleSubmit}
        feedbackText={feedbackText}
      />
    </div>
  )
}

export default Like
