import React from "react"

import { Button, Modal, ModalBody } from "reactstrap"

import ChatAssistantIcon from "../../../../../assets/img/chatAssistantIcon.png"

import ChatBackAndForthWelcomeIcon from "../../../../../assets/img/chatBackAndForthWelcomeIcon.png"
import CopyToClipboardWelcomeIcon from "../../../../../assets/img/copyToClipboardWelcomeIcon.png"
import SendMessageWelcomeIcon from "../../../../../assets/img/sendMessageWelcomeIcon.png"
import WhiteCheckIcon from "../../../../../assets/img/whiteCheckIcon.png"

import "./style.scss"

const WelcomeChatModal = ({ onAgreeSubmit }) => {
  return (
    <Modal
      isOpen={true}
      backdrop={true}
      centered={true}
      className="welcome-chat-modal-wrapper"
      scrollable={true}
    >
      <ModalBody className="welcome-chat-modal">
        <div className="welcome-chat-modal-header">
          <img
            src={ChatAssistantIcon}
            className="welcome-chat-modal-header-logo"
            alt="logo"
          />
          <div className="welcome-chat-modal-header-title">
            Welcome to PRC Macro!
          </div>
          <div className="welcome-chat-modal-header-subtitle">
            Dive into the political economy in China using our AI powered chat.
          </div>
        </div>
        <div className="welcome-chat-modal-content">
          <div className="welcome-chat-modal-content-row">
            <img
              src={SendMessageWelcomeIcon}
              className="row-icon"
              alt="Send Message"
            />
            <div className="row-block">
              <div className="row-title">Start a Chat</div>
              <div className="row-text">
                Ask about anything PRC Macro has reported on in the past decade.
                PRC Macro does the research for you and responds within seconds.
              </div>
            </div>
          </div>
          <div className="welcome-chat-modal-content-row">
            <img
              src={ChatBackAndForthWelcomeIcon}
              className="row-icon"
              alt="chat back and forth"
            />
            <div className="row-block">
              <div className="row-title">Chat Back-and-Forth</div>
              <div className="row-text">
                Want to dig deeper or read a shorter summary? Send a follow-up
                message and PRC Macro will take it from there.
              </div>
            </div>
          </div>
          <div className="welcome-chat-modal-content-row">
            <img
              src={CopyToClipboardWelcomeIcon}
              className="row-icon"
              alt="copy to clipboard"
            />
            <div className="row-block">
              <div className="row-title">Copy to Clipboard</div>
              <div className="row-text">
                Easily share insights with your team. Copy your conversation to
                your clipboard to save and share with others.
              </div>
            </div>
          </div>
        </div>
        <div className="welcome-chat-modal-footer">
          <div className="welcome-chat-modal-footer-left">
            By clicking "Agree," you agree to the PRC
            <br /> Macro{" "}
            <a
              href="https://client.prcmacro.com/user/settings"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>{" "}
            and{" "}
            <a
              href="https://client.prcmacro.com/user/settings"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </div>
          <div className="welcome-chat-modal-footer-right">
            <Button
              className="text-capitalize submit-button"
              onClick={onAgreeSubmit}
            >
              <img src={WhiteCheckIcon} className="icon" />
              Agree
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default WelcomeChatModal
