import React from "react"
import routes from "routes.js"
import { Route, Switch } from "react-router-dom"
import { WelcomeChatModal } from "WebVisual/Components/Chat/components"
import { useSelector, useDispatch } from "react-redux"
import { constants } from "WebVisual/Components/Settings/redux"

const User = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.SignIn.user)

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }

      if (prop.layout === "/user") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  const handleAgreeSubmit = () => {
    dispatch({
      type: constants.UPDATE_USER_PROFILE,
      data: {
        ...user,
        user_profile: { ...user.user_profile, has_seen_welcome_popup: true }
      }
    })
  }

  return (
    <>
      {!user.user_profile.has_seen_welcome_popup ? (
        <WelcomeChatModal onAgreeSubmit={handleAgreeSubmit} />
      ) : null}
      <Switch>{getRoutes(routes)}</Switch>
    </>
  )
}

export default User
