import { useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"

export const useHistoryState = () => {
  const history = useHistory()
  const location = useLocation()

  const message = location.state?.message

  const handleClearState = useCallback(() => {
    history.replace({
      pathname: history.location.pathname,
      state: null
    })
  }, [history])

  return {
    handleClearState,
    message
  }
}
